import Heading2 from "../core/components/Heading2";
import Heading3 from "../core/components/Heading3";
import Heading31 from "../core/components/Heading31";
import Paragraph from "../core/components/Paragraph";
import Paragraph1 from "../core/components/Paragraph1";
import tracker from "../core/hooks/useAnalyticsEventTracker";
import s from "../core/styles/general.styles";
import React, { useEffect } from "react";

const TermsAndConditionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section
        className={`pt-6 sm:pt-6 md:pt-10 lg:pt-10 xl:pt-10 2xl:pt-10 pb-10 ${s.sectionPaddings}`}
      >
        <Heading2 className="mb-2 font-bold">TERMS OF SERVICE</Heading2>
        <Heading31 className="mb-2 font-bold uppercase">
          1. Agreement to terms
        </Heading31>
        <Paragraph1 className="mb-1">
          These Terms of Service constitute a legally binding agreement made
          between you, whether personally or on behalf of an entity (&quot;you”)
          and Wokelo Inc., doing business as Wokelo AI (
          <b>&quot;Wokelo AI&quot;, &quot;we”, &quot;us”, or &quot;our”</b>
          ), concerning your access to and use of the wokelo.ai website as well
          as any other media form, media channel, mobile website or mobile
          application related, linked, or otherwise connected thereto
          (collectively, the &quot;Site”). We are registered in Washington,
          United States and have our registered office at Redmond, WA 98052. You
          agree that by accessing the Site, you have read, understood, and agree
          to be bound by all of these Terms of Service. IF YOU DO NOT AGREE WITH
          ALL OF THESE TERMS OF SERVICE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
          USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Supplemental terms and conditions or documents that may be posted on
          the Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Service at any time and for
          any reason. We will alert you about any changes by updating the
          &quot;Last updated” date of these Terms of Service, and you waive any
          right to receive specific notice of each such change. Please ensure
          that you check the applicable Terms every time you use our Site so
          that you understand which Terms apply. You will be subject to, and
          will be deemed to have been made aware of and to have accepted, the
          changes in any revised Terms of Service by your continued use of the
          Site after the date such revised Terms of Service are posted.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          The Site is not tailored to comply with industry-specific regulations
          (Health Insurance Portability and Accountability Act (HIPAA), Federal
          Information Security Management Act (FISMA), etc.), so if your
          interactions would be subjected to such laws, you may not use this
          Site. You may not use the Site in a way that would violate the
          GrammLeach-Bliley Act (GLBA).
        </Paragraph1>

        <Paragraph1 className="mb-1">
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or register for the Site.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          2. INTELLECTUAL PROPERTY RIGHTS
        </Heading31>

        <Paragraph1 className="mb-1">
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, international copyright laws, and international
          conventions. The Content and the Marks are provided on the Site “AS
          IS” for your information and personal use only. Except as expressly
          provided in these Terms of Service, no part of the Site and no Content
          or Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose whatsoever, without our express prior written permission.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">3. USER REPRESENTATIONS</Heading31>

        <Paragraph1 className="mb-1">
          By using the Site, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these Terms
          of Service; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Site through automated or
          non-human means, whether through a bot, script or otherwise; (6) you
          will not use the Site for any illegal or unauthorized purpose; and (7)
          your use of the Site will not violate any applicable law or
          regulation. If you provide any information that is untrue, inaccurate,
          not current, or incomplete, we have the right to suspend or terminate
          your account and refuse any and all current or future use of the Site
          (or any portion thereof).
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">4. USER REGISTRATION</Heading31>

        <Paragraph1 className="mb-1">
          You may be required to register with the Site. You agree to keep your
          password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">5. FEES AND PAYMENT</Heading31>

        <Paragraph1 className="mb-1">
          You may be required to purchase or pay a fee to access some of our
          services. You agree to provide current, complete, and accurate
          purchase and account information for all purchases made via the Site.
          You further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you as
          needed. We bill you through an online billing account for purchases
          made via the Site. Sales tax will be added to the price of purchases
          as deemed required by us. We may change prices at any time. All
          payments shall be in USD.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          You agree to pay all charges or fees at the prices then in effect for
          your purchases, and you authorize us to charge your chosen payment
          provider for any such amounts upon making your purchase.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We reserve the right to correct any errors or mistakes in pricing,
          even if we have already requested or received payment. We also reserve
          the right to refuse any order placed through the Site.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">6. CANCELLATION</Heading31>

        <Paragraph1 className="mb-1">
          You can cancel your subscription at any time. Your cancellation will
          take effect at the end of the current paid term.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          If you are unsatisfied with our services, please email us at
          support@wokelo.ai or call us at 4254948817.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">7. PROHIBITED ACTIVITIES</Heading31>

        <Paragraph1 className="mb-1">
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavours except those that are
          specifically endorsed or approved by us. As a user of the Site, you
          agree not to:
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any Content or enforce limitations on the use of the
          Site and/or the Content contained therein.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Site. Use any information obtained from the Site in order to harass,
          abuse, or harm another person.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Use the Site in a manner inconsistent with any applicable laws or
          regulations. Engage in unauthorized framing of or linking to the Site.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Site or modifies, impairs, disrupts, alters, or interferes with the
          use, features, functions, operation, or maintenance of the Site.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Delete the copyright or other proprietary rights notice from any
          Content. Attempt to impersonate another user or person or use the
          username of another user.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as “spyware” or “passive
          collection mechanisms” or “pcms”).
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Interfere with, disrupt, or create an undue burden on the Site or the
          networks or services connected to the Site.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Site to you.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site, or any portion of the Site. Copy or adapt
          the Site&apos;s software, including but not limited to Flash, PHP,
          HTML, JavaScript, or other code. Except as permitted by applicable
          law, decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Site.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Site, or using
          or launching any unauthorized script or other software.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Use a buying agent or purchasing agent to make purchases on the Site.
          Make any unauthorized use of the Site, including collecting usernames
          and/or email addresses of users by electronic or other means for the
          purpose of sending unsolicited email, or creating user accounts by
          automated means or under false pretences.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Use the Site as part of any effort to compete with us or otherwise use
          the Site and/or the Content for any revenue-generating endeavour or
          commercial enterprise.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          8. USER GENERATED CONTRIBUTIONS
        </Heading31>

        <Paragraph1 className="mb-1">
          The Site does not offer users to submit or post content. We may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          &quot;Contributions&quot;). Contributions may be viewable by other
          users of the Site and through third-party websites. As such, any
          Contributions you transmit may be treated in accordance with the Site
          Privacy Policy. When you create or make available any Contributions,
          you thereby represent and warrant that:
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">9. CONTRIBUTION LICENSE</Heading31>

        <Paragraph1 className="mb-1">
          You and the Site agree that we may access, store, process, and use any
          information and personal data that you provide following the terms of
          the Privacy Policy and your choices (including settings).
        </Paragraph1>

        <Paragraph1 className="mb-1">
          By submitting suggestions or other feedback regarding the Site, you
          agree that we can use and share such feedback for any purpose without
          compensation to you. We do not assert any ownership over your
          Contributions. You retain full ownership of all of your Contributions
          and any intellectual property rights or other proprietary rights
          associated with your Contributions. We are not liable for any
          statements or representations in your Contributions provided by you in
          any area on the Site. You are solely responsible for your
          Contributions to the Site and you expressly agree to exonerate us from
          any and all responsibility and to refrain from any legal action
          against us regarding your Contributions.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">10. SUBMISSIONS</Heading31>

        <Paragraph1 className="mb-1">
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          (&quot;Submissions&quot;) provided by you to us are non-confidential
          and shall become our sole property. We shall own exclusive rights,
          including all intellectual property rights, and shall be entitled to
          the unrestricted use and dissemination of these Submissions for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">11. U.S. GOVERNMENT RIGHTS</Heading31>

        <Paragraph1 className="mb-1">
          Our services are “commercial items” as defined in Federal Acquisition
          Regulation (“FAR”) 2.101. If our services are acquired by or on behalf
          of any agency not within the Department of Defence (“DOD”), our
          services are subject to the terms of these Terms of Service in
          accordance with FAR 12.212 (for computer software) and FAR 12.211 (for
          technical data). If our services are acquired by or on behalf of any
          agency within the Department of Defence, our services are subject to
          the terms of these Terms of Service in accordance with Defence Federal
          Acquisition Regulation (“DFARS”) 227.7202-3. In addition, DFARS
          252.227-7015 applies to technical data acquired by the DOD. This U.S.
          Government Rights clause is in lieu of, and supersedes, any other FAR,
          DFARS, or other clause or provision that addresses government rights
          in computer software or technical data under these Terms of Service.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">12. SITE MANAGEMENT</Heading31>

        <Paragraph1 className="mb-1">
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms of Service; (2) take appropriate legal
          action against anyone who, in our sole discretion, violates the law or
          these Terms of Service, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the
          Site in a manner designed to protect our rights and property and to
          facilitate the proper functioning of the Site.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">13. TERM AND TERMINATION</Heading31>

        <Paragraph1 className="mb-1">
          These Terms of Service shall remain in full force and effect while you
          use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
          SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
          INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          14. MODIFICATIONS AND INTERRUPTIONS
        </Heading31>

        <Paragraph1 className="mb-1">
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time. We will not be liable to
          you or any third party for any modification, price change, suspension,
          or discontinuance of the Site.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during any downtime or
          discontinuance of the Site. Nothing in these Terms of Service will be
          construed to obligate us to maintain and support the Site or to supply
          any corrections, updates, or releases in connection therewith.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">15. GOVERNING LAW</Heading31>

        <Paragraph1 className="mb-1">
          These Terms of Service and your use of the Site are governed by and
          construed in accordance with the laws of the State of Delaware
          applicable to agreements made and to be entirely performed within the
          State of Delaware, without regard to its conflict of law principles.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">16. DISPUTE RESOLUTION</Heading31>

        <Paragraph1 className="mb-1">
          Any legal action of whatever nature brought by either you or us
          (collectively, the “Parties” and individually, a “Party”) shall be
          commenced or prosecuted in the state and federal courts located in
          USA, Delaware, and the Parties hereby consent to, and waive all
          defences of lack of personal jurisdiction and forum non conveniens
          with respect to venue and jurisdiction in such state and federal
          courts. Application of the United Nations Convention on Contracts for
          the International Sale of Goods and the Uniform Computer Information
          Transaction Act (UCITA) are excluded from these Terms of Service. In
          no event shall any claim, action, or proceeding brought by either
          Party related in any way to the Site be commenced more than one (1)
          years after the cause of action arose.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">17. CORRECTIONS</Heading31>

        <Paragraph1 className="mb-1">
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">18. DISCLAIMER</Heading31>

        <Paragraph1 className="mb-1">
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
          NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
          FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
          WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
          WHERE APPROPRIATE.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          19. LIMITATIONS OF LIABILITY
        </Heading31>

        <Paragraph1 className="mb-1">
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER
          OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH
          PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $100.00 USD. CERTAIN US
          STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
          WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">20. INDEMNIFICATION</Heading31>

        <Paragraph1 className="mb-1">
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) use of the Site;
          (2) breach of these Terms of Service; (3) any breach of your
          representations and warranties set forth in these Terms of Service;
          (4) your violation of the rights of a third party, including but not
          limited to intellectual property rights; or (5) any overt harmful act
          toward any other user of the Site with whom you connected via the
          Site. Notwithstanding the foregoing, we reserve the right, at your
          expense, to assume the exclusive defence and control of any matter for
          which you are required to indemnify us, and you agree to cooperate, at
          your expense, with our defence of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">21. USER DATA</Heading31>

        <Paragraph1 className="mb-1">
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site. Although we perform regular routine
          backups of data, you are solely responsible for all data that you
          transmit or that relates to any activity you have undertaken using the
          Site. You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        </Heading31>

        <Paragraph1 className="mb-1">
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
          ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
          POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
          VIA THE SITE. You hereby waive any rights or requirements under any
          statutes, regulations, rules, ordinances, or other laws in any
          jurisdiction which require an original signature or delivery or
          retention of non-electronic records, or to payments or the granting of
          credits by any means other than electronic means.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          23. CALIFORNIA USERS AND RESIDENTS
        </Heading31>

        <Paragraph1 className="mb-1">
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </Paragraph1>


        <Heading31 className="mt-4 mb-2">24. Cookies</Heading31>

        <Paragraph1 className="mb-1">
        By using this service, you agree to the use of necessary authentication cookies that are essential for the proper functioning of our platform. These cookies help us secure your account and enhance your user experience. Continued use of our services constitutes your acceptance of this policy.
        </Paragraph1>


        <Heading31 className="mt-4 mb-2">25. MISCELLANEOUS</Heading31>

        <Paragraph1 className="mb-1">
          These Terms of Service and any policies or operating rules posted by
          us on the Site or in respect to the Site constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Terms of Service
          shall not operate as a waiver of such right or provision. These Terms
          of Service operate to the fullest extent permissible by law. We may
          assign any or all of our rights and obligations to others at any time.
          We shall not be responsible or liable for any loss, damage, delay, or
          failure to act caused by any cause beyond our reasonable control. If
          any provision or part of a provision of these Terms of Service is
          determined to be unlawful, void, or unenforceable, that provision or
          part of the provision is deemed severable from these Terms of Service
          and does not affect the validity and enforceability of any remaining
          provisions. There is no joint venture, partnership, employment or
          agency relationship created between you and us as a result of these
          Terms of Service or use of the Site. You agree that these Terms of
          Service will not be construed against us by virtue of having drafted
          them. You hereby waive any and all defences you may have based on the
          electronic form of these Terms of Service and the lack of signing by
          the parties hereto to execute these Terms of Service.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">26. CONTACT US</Heading31>

        <Paragraph1 className="mb-1">
          In order to resolve a complaint regarding the Site or to receive
          further information regarding use of the Site, please contact us at:
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>
            Wokelo Inc.
            <br />
            Redmond, WA 98052
            <br />
            United States
            <br />
            {/* Phone: 4254948817
						<br /> */}
            support@wokelo.ai
          </b>
        </Paragraph1>
      </section>
    </div>
  );
};

export default TermsAndConditionsPage;
