import React, { useEffect } from "react";
import Soc2 from "../Home/WhyCustomerLoveWokelo";
import TrustedBy from "../Home/TrustedBy";
import Form from "./Form";
import Footer from "../Footer";
import ThankYouHeader from "./ThankYouHeader";

type Props = {};

const ThankYou = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col relative">
      <ThankYouHeader />
      <Footer></Footer>
    </div>
  );
};

export default ThankYou;
