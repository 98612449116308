import * as React from 'react';

function Check(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={13} height={10} fill="none" {...props}>
			<path
				d="M12.588.609a.917.917 0 00-1.302 0l-6.83 6.838L1.589 4.57A.937.937 0 00.286 5.917l3.52 3.52a.917.917 0 001.302 0l7.48-7.48a.917.917 0 000-1.348z"
				fill="currentColor"
			/>
		</svg>
	);
}

const CheckIcon = React.memo(Check);
export default CheckIcon;
