import React from "react";
import { ComponentProps } from "../types/component-props";

const Paragraph = ({ className, children }: ComponentProps) => {
  return (
    <p className={`font-normal text-md md:text-lg ${className}`}>{children}</p>
  );
};

export default Paragraph;
