import React, { useEffect, useRef, useState } from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import useMediaQuery from "@mui/material/useMediaQuery";

import RoundCircleIcon from "../../icons/RoundCircle";
import DotLinesIcon from "../../icons/DotLines";
import GraphIcon from "../../icons/Graph";
import ClockIcon from "../../icons/Clock";
import Input from "../Input";
import LineIcon from "../../icons/Line";
import { useInView, motion } from "framer-motion";

type Props = {};

const variants = [
  {
    hidden: { opacity: 0, y: +283 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 40,
        damping: 20,
        duration: 10,
      },
    },
  },
  {
    hidden: { opacity: 0, y: +300 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 40,
        damping: 20,
        duration: 10,
      },
    },
  },
];

const AmplifyHumanProductivity = React.forwardRef((props: Props, ref?: any) => {
  const matches = useMediaQuery("(min-width:1300px)");
  const matches1 = useMediaQuery("(min-width:750px)");
  const [email, setEmail] = useState("");

  // useInView hook usage
  const animationref = useRef(null);
  const isInView = useInView(animationref);

  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [triggerAnimation1, setTriggerAnimation1] = useState(false);

  useEffect(() => {
    if (!triggerAnimation && isInView) {
      setTimeout(() => {
        setTriggerAnimation(true);
      }, 200);
    } else if (!triggerAnimation1 && triggerAnimation && isInView) {
      setTimeout(() => {
        setTriggerAnimation1(true);
      }, 1000);
    }
  }, [isInView, triggerAnimation, triggerAnimation1]);

  return (
    <section className="relative bg-[#202020] my-5 h-auto lg:min-h-[100vh]">
      <div
        className={`flex flex-col px-[20px] sm:px-[20px] md:px-[20px] lg:px-[120px] xl:px-[120px] pt-[40px] md:pt-[80px]`}
      >
        <div
          className={`w-full flex flex-col flex-wrap lg:flex-row lg:justify-start items-start`}
        >
          <div
            className={`flex flex-col w-full ${
              matches ? "lg:w-[60%]" : ""
            }  z-10`}
          >
            <div className="w-full flex ">
              <Heading className="text-w-light-white">
                Amplify human productivity
              </Heading>
            </div>
            <div className="mt-[15px]">
              <Paragraph className="text-w-light-white">
                Focus on decision-making while Wokelo does the heavy lifting.
                <br />
                Perform weeks of work in an instant.
              </Paragraph>
            </div>
            <div className="flex justify-center sm:justify-start my-9">
              <Input
                className="z-30 bg-[#343434] border border-[rgba(227,214,199,0.30)]"
                inputClassName="text-[#E3D6C7] placeholder:text-[#E3D6C7]"
                placeholder="Business email"
                variant="cream"
                value={email}
                onInput={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div
            className={`w-full ${
              matches ? "lg:w-[40%]" : ""
            } flex justify-center`}
          >
            <div
              ref={animationref}
              className={`${matches ? "lg:absolute right-[100px]" : ""} `}
            >
              <div className={`bg-[#202020]`}>
                <div
                  className={`flex ${
                    matches ? "justify-end" : "justify-center"
                  } items-end gap-5`}
                >
                  <motion.div
                    variants={variants[0]}
                    initial="hidden"
                    animate={triggerAnimation ? "show" : "hidden"}
                    className={`${
                      matches1 ? "Phone" : "Phone_m"
                    } w-[132px] h-auto md:w-[240px] md:h-[160px] pt-[24px] px-[30px]`}
                  >
                    <h3 className="text-[18px] leading-[16px] md:text-[28px] text-center text-w-cream md:leading-[25px]">
                      4-5 <br></br>weeks
                    </h3>
                    <h6 className="mt-[16px] w-full font-light text-center text-[14px] leading-[14px] md:text-[21px] text-w-cream md:leading-[21px]">
                      Current manual processes
                    </h6>
                  </motion.div>
                  <motion.div
                    variants={variants[1]}
                    initial="hidden"
                    animate={triggerAnimation1 ? "show" : "hidden"}
                    className={`${
                      matches1 ? "Phone1" : "Phone1_m"
                    } w-[198px] h-auto  md:w-[360px] md:h-[440px] pt-[47px] px-[30px]`}
                  >
                    <h3 className="font-[700] text-[30px] leading-[36px]  md:text-[80px] text-center text-w-cream md:leading-[80px]">
                      10x <br></br>faster
                    </h3>
                    <div className="mt-[20px] md:mt-[50px] w-full flex justify-center">
                      <picture>
                        <source srcSet={"/logo_white.svg"} type="image/webp" />
                        <img
                          src={"/logo_white.svg"}
                          alt="Hero"
                          className="z-30 object-scale-down w-[87px] md:w-[184px] h-auto"
                        />
                      </picture>
                    </div>
                    <h6 className="mt-[22px] md:mt-[30px] font-light text-center text-[12px] leading-[12px] md:text-[21px] text-w-cream md:leading-[21px]">
                      With Wokelo.ai
                    </h6>
                  </motion.div>
                </div>
                <div className="mt-4">
                  <LineIcon className="w-[360px] h-auto md:w-full" />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`mt-[40px] ${
            matches ? "lg:mt-[250px]" : ""
          }  mb-[60px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2`}
        >
          <div className="py-4 md:py-8 md:border-r md:border-r-[#B6BEB5] md:border-b md:border-b-[#B6BEB5]">
            <div className="flex gap-3">
              <ClockIcon />
              <h3 className="font-[550] text-w-light-white text-[20px]">
                Hyper-Accelerated Insights
              </h3>
            </div>
            <div className="mt-[14px] w-[90%]">
              <p className="text-[16px] md:text-[18px] leading-[19px] md:leading-[22px] text-w-light-white">
                Unearth valuable insights that traditionally took weeks
              </p>
            </div>
          </div>
          <div className="md:pl-12 py-4 md:py-8 md:border-b md:border-b-[#B6BEB5]">
            <div className="flex gap-3">
              <GraphIcon />
              <h3 className="font-[550] text-w-light-white text-[20px]">
                Refined Deliverables
              </h3>
            </div>
            <div className="mt-[14px] w-[90%] md:w-full">
              <p className="text-[16px] md:text-[18px] leading-[19px] md:leading-[22px] text-w-light-white">
                Instantly receive high-quality customized outputs
              </p>
            </div>
          </div>
          <div className="py-4 md:py-8 md:border-r md:border-r-[#B6BEB5]">
            <div className="flex gap-3">
              <RoundCircleIcon />
              <h3 className="font-[550] text-w-light-white text-[20px]">
                Human-Like Synthesis
              </h3>
            </div>
            <div className="mt-[14px] w-[90%]">
              <p className="text-[16px] md:text-[18px] leading-[19px] md:leading-[22px] text-w-light-white">
                Experience the pinnacle of AI, process data with nuance
              </p>
            </div>
          </div>
          <div className="md:pl-12 py-4 md:py-8">
            <div className="flex gap-3">
              <DotLinesIcon />
              <h3 className="font-[550] text-w-light-white text-[20px]">
                Seamless Source Integration
              </h3>
            </div>
            <div className="mt-[14px] w-[90%]">
              <p className="text-[16px] md:text-[18px] leading-[19px] md:leading-[22px] text-w-light-white">
                Leverage preferred data sources and connectors
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default AmplifyHumanProductivity;
