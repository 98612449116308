import * as React from "react";

function LinkedIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_706_2118)">
        <path
          d="M17.04 17.043H14.078V12.403C14.078 11.296 14.055 9.872 12.534 9.872C10.99 9.872 10.754 11.076 10.754 12.321V17.043H7.793V7.5H10.637V8.8H10.676C11.073 8.05 12.04 7.26 13.484 7.26C16.485 7.26 17.04 9.234 17.04 11.805V17.043ZM4.447 6.194C3.493 6.194 2.727 5.423 2.727 4.474C2.727 3.525 3.494 2.754 4.447 2.754C4.90317 2.754 5.34066 2.93521 5.66322 3.25778C5.98579 3.58034 6.167 4.01783 6.167 4.474C6.167 4.93017 5.98579 5.36766 5.66322 5.69022C5.34066 6.01279 4.90317 6.194 4.447 6.194ZM5.931 17.044H2.961V7.5H5.931V17.043V17.044ZM18.521 0H1.476C0.66 0 0 0.645 0 1.44V18.56C0 19.355 0.66 20 1.476 20H18.518C19.333 20 20 19.356 20 18.56V1.44C20 0.646 19.333 0 18.518 0H18.521Z"
          fill="#2D2D2D"
        />
      </g>
      <defs>
        <clipPath id="clip0_706_2118">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const LinkedInIcon = React.memo(LinkedIn);
export default LinkedInIcon;
