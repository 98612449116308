import * as React from "react";

function Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <rect width="50" height="50" rx="25" fill="white" />
      <rect
        x="17"
        y="16"
        width="24"
        height="1"
        transform="rotate(45 17 16)"
        fill="black"
      />
      <rect
        x="16"
        y="33"
        width="24"
        height="1"
        transform="rotate(-45 16 33)"
        fill="black"
      />
      <rect
        x="0.5"
        y="0.5"
        width="49"
        height="49"
        rx="24.5"
        stroke="black"
        strokeOpacity="0.4"
      />
    </svg>
  );
}

const CloseIcon = React.memo(Close);
export default CloseIcon;
