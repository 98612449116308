import { Link } from "react-router-dom";
import Heading2 from "../core/components/Heading2";
import Heading3 from "../core/components/Heading3";
import Heading4 from "../core/components/Heading4";
import Paragraph from "../core/components/Paragraph";
import tracker from "../core/hooks/useAnalyticsEventTracker";
import React, { useEffect, useState } from "react";
import Paragraph1 from "../core/components/Paragraph1";
import Heading from "../core/components/Heading";
import QuoteIcon from "../core/icons/Quote";
import Input1 from "../core/components/Input1";
import "../App.css";

const s = {
  heroPaddings: `
          px-[25px] sm:px-[25px] md:px-[25px] lg:px-[120px] xl:px-[120px] 2xl:px-[120px]
          py-[30px] sm:py-[30px] md:py-[30px] lg:pt-[60px] xl:pt-[60px] 2xl:pt-[60px]
      `,
  heroInner: "lg:w-[1000px] xl:w-[1149px] 2xl:w-[1149px] lg:mx-auto",
  sectionPaddings:
    "px-[20px] sm:px-[20px] md:px-[20px] lg:px-[20px] xl:px-[120px] 2xl:px-[120px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1200px] lg:mx-auto xl:mx-auto 2xl:mx-auto",
  container: {
    cols2:
      "flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row",
    cols2Inverted:
      "flex flex-col-reverse sm:flex-col-reverse md:flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row",
    col: "basis-full sm:basis-full md:basis-full lg:basis-1/2 xl:basis-1/2 2xl:basis-1/2",
  },
};

const AboutUsPage = () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="flex flex-col">
        <section
          className={`w-full bg-[#202020] ${s.heroPaddings} py-4 md:py-10`}
        >
          <div className="w-full flex flex-col-reverse lg:flex-row justify-between items-start gap-4">
            <div className="hidden lg:block">
              <picture>
                <source srcSet="/abstract.svg" type="image/webp" />
                <img
                  src="/abstract.svg"
                  alt=""
                  className="z-30 object-scale-down w-full h-auto my-auto"
                />
              </picture>
            </div>
            <div className="relative w-full h-[300px] md:h-[400px] mt-6 lg:hidden flex justify-end">
              <picture>
                <source srcSet="/abstract.png" type="image/webp" />
                <img
                  src="/abstract.png"
                  alt=""
                  className="absolute right-[-6%] md:right-[-1%] z-30 object-scale-down w-[336px] md:w-[412px] h-auto"
                />
              </picture>
            </div>
            <div className="w-full lg:w-[60%] lg:mt-[40px] lg:pl-[50px]">
              <div className="w-full flex ">
                <h1 className="text-w-light-white font-light text-[40px] leading-[40px] md:text-[80px] md:leading-[72px]">
                  Our vision
                </h1>
              </div>
              <div className="my-[30px] w-full">
                <Paragraph className="text-w-light-white font-light">
                  We dream of a world where humans actively harness machine
                  intelligence securely and ethically, magnifying societal
                  productivity.
                </Paragraph>
              </div>
              <div>
                <Paragraph className="text-w-light-white font-light">
                  At Wokelo, we are building a new kind of infrastructure to
                  power the knowledge economy. An intelligent decision support
                  for intricate cognitive workloads tailormade for investment
                  and strategy professionals.
                </Paragraph>
              </div>
            </div>
          </div>
        </section>

        <section className={`flex flex-col ${s.heroPaddings}`}>
          <div>
            <div className="flex justify-between text-w-light-black">
              <Heading>Why we started Wokelo</Heading>
              <p className="text-[18px]">/////</p>
            </div>
            <div>
              <Paragraph className="mt-3 w-full md:w-[60%] text-[#1D1D1D]">
                Investment research has always been a challenging, costly, and
                time-consuming task. The conventional approaches have often been
                confined and linear.
              </Paragraph>
            </div>
            <div className="mt-[30px] md:mt-[60px] grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-16">
              <div className="">
                <Paragraph className="text-[#1D1D1D]">
                  They involve navigating through a vast array of disparate
                  sources such as news, filings, analyst reports as well as
                  alternative data like tweets, blogs, podcasts, and more.
                  Merging these, framing narratives, and compiling into
                  comprehensive presentations and reports, often consumes an
                  overwhelming majority of a team’s efforts.
                </Paragraph>
              </div>
              <div className="">
                <Paragraph className="text-[#1D1D1D]">
                  These tasks necessitated human-level text abstraction
                  abilities, previously deemed non automatable. Sadly, this
                  extended process often leads to missed opportunities, stunted
                  growth, and sometimes, exorbitant outsourcing costs or
                  settling for superficial syndicated reports.
                </Paragraph>
              </div>
            </div>
          </div>
        </section>

        <div className={`${s.heroPaddings}`}>
          <div className="border-t border-t-[#1D1D1D]"></div>
        </div>

        <section className={`flex flex-col ${s.heroPaddings}`}>
          <div>
            <div className="flex justify-between text-w-light-black">
              <Heading>Full stack platform for high fidelity</Heading>
              <p className="hidden md:block text-[18px]">/////</p>
            </div>
            <div>
              <Paragraph className="mt-3 w-full md:w-[60%] text-[#1D1D1D]">
                Large Language Models (LLMs) introduced a unique avenue to
                streamline these exhaustive processes. But we recognized it's
                not about merely overlaying a UI wrapper on these LLMs.
                High-stakes decisions in investment research demand pinpoint
                accuracy.
              </Paragraph>
            </div>
            <div className="my-10">
              <picture>
                <source srcSet="/illustration.svg" type="image/webp" />
                <img
                  src="/illustration.svg"
                  alt=""
                  className="z-30 object-scale-down w-full h-auto my-auto"
                />
              </picture>
            </div>
            <div className="mt-[30px] md:mt-[60px] grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-16">
              <div className="">
                <Paragraph className="text-[#1D1D1D]">
                  Investment professionals in this domain yearn for more than
                  mere summarization tools. They seek reliable insights,
                  authentic data sourcing, fact verification, and guaranteed
                  security for their proprietary findings.
                </Paragraph>
              </div>
              <div className="">
                <Paragraph className="text-[#1D1D1D]">
                  Wokelo offers precisely that. Our platform is a one-stop
                  solution: from data aggregation for content selection,
                  LLM-driven synthesis that mirrors human cognition, to instant
                  generation of tailor-made reports.
                </Paragraph>
              </div>
            </div>
          </div>
        </section>

        <section
          id="how-it-works"
          className="relative bg-[#202020] tower my-[70px]"
        >
          <div
            className={` flex flex-col px-[20px] sm:px-[20px] md:px-[20px] lg:px-[120px] xl:px-[120px] py-[40px] md:py-[80px]`}
          >
            <div className="flex justify-between items-start md:items-center text-w-light-black">
              <Heading className="w-[80%] md:w-full text-w-light-white">
                The minds behind Wokelo
              </Heading>
              <p className="text-[18px] text-w-light-white">/////</p>
            </div>
            <div>
              <Paragraph className="w-full md:w-[58%] mt-2 text-w-light-white">
                Our founders, Siddhant Masson and Saswat Nanda, are no strangers to
                the challenges of investment and business research. With roots
                in reputable consulting firms and experience spanning strategy,
                venture capital, and M&A, they comprehend the intricacies of the
                field. They realized that existing point solutions and simple AI
                summarizers could never meaningfully fulfill the rigorous
                standards of this industry.
              </Paragraph>
            </div>
            <div className="relative w-[80%] md:w-[58%] mt-[40px] md:mt-[200px]">
              <div className="absolute">
                <QuoteIcon />
              </div>
              <Paragraph className="mt-[40px] ml-[40px] text-w-light-white">
                Our journey has attracted the backing of renowned venture funds
                and AI pioneers. We're proud to partner with trailblazers like
                Yohei Nakajima, the genius behind babyAGI, and machine learning
                maestro-turned VC, Shruti Gandhi. Our circle of supporters
                boasts global leaders in consulting, investment banking, and
                private equity.
              </Paragraph>
            </div>
            <div className="relative h-[200px] md:hidden">
              <div className="md:hidden">
                <picture>
                  <source srcSet="/tower1.png" type="image/webp" />
                  <img
                    src="/tower1.png"
                    alt=""
                    className="absolute top-[-180px] right-[-5%] sm:right-[-1%] w-[300px]"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>

        <section className={`flex flex-col ${s.heroPaddings}`}>
          <div className="flex flex-col md:flex-row justify-center md:justify-between items-start">
            <div className="w-full text-w-light-black">
              <h1 className="w-full md:w-[70%] text-[28px] md:text-[45px] leading-[33px] md:leading-[54px]">
                Join us in our mission to revolutionize investment research.
              </h1>
              <Paragraph className="mt-[30px] w-full text-[#1D1D1D]">
                Say hello to the future of work, welcome to Wokelo.
              </Paragraph>
            </div>
            <div>
              <div className="hidden md:flex justify-end mt-3">
                <p className=" text-[18px]">/////</p>
              </div>
              <div className="mt-[30px] md:mt-[60px]">
                <Input1
                  className="border-[1px] border-[rgba(52,52,52,0.40)] bg-transparent"
                  placeholder="Business email"
                  inputClassName={"text-w-black placeholder:text-w-black"}
                  value={email}
                  onInput={(e) => {
                    setEmail(e.currentTarget.value);
                  }}
                  fullWidth
                  variant="primary"
                />
              </div>
            </div>
          </div>
        </section>

        <div className={`${s.heroPaddings}`}>
          <div className="border-t border-t-[#1D1D1D]"></div>
        </div>

        <section className={`flex flex-col ${s.heroPaddings}`}>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between gap-8">
            <div className="w-full lg:w-[14%] flex justify-center lg:justify-start">
              <p className="text-[12px] md:text-[16px]">Backed by:</p>
            </div>
            <div
              className={`w-full mb-[20px]  flex flex-row flex-wrap gap-6 md:gap-12 justify-center lg:justify-end items-center`}
            >
              <div>
                <picture>
                  <source
                    srcSet="/client/logos/arrayvc.svg"
                    type="image/webp"
                  />
                  <img
                    src="/client/logos/arrayvc.svg"
                    alt="Hero"
                    className="z-30 object-scale-down w-[82px] md:w-[120px] h-[20px] my-auto"
                  />
                </picture>
              </div>
              <div>
                <picture>
                  <source
                    srcSet="/client/logos/untapped.svg"
                    type="image/webp"
                  />
                  <img
                    src="client/logos/untapped.svg"
                    alt="Hero"
                    className="z-30 object-scale-down w-[82px] md:w-full h-[20px] my-auto"
                  />
                </picture>
              </div>
              <div>
                <picture>
                  <source srcSet="/SeaChange.svg" type="image/webp" />
                  <img
                    src="/SeaChange.svg"
                    alt="Hero"
                    className="z-30 object-scale-down w-[82px] h-auto my-auto"
                  />
                </picture>
              </div>
              <div>
                <picture>
                  <source
                    srcSet="/client/logos/packventures.svg"
                    type="image/webp"
                  />
                  <img
                    src="/client/logos/packventures.svg"
                    alt="Hero"
                    className="z-30 object-scale-down w-[82px] md:w-full h-[22px] my-auto"
                  />
                </picture>
              </div>
              <div>
                <picture>
                  <source
                    srcSet="/client/logos/upsparks.svg"
                    type="image/webp"
                  />
                  <img
                    src="/client/logos/upsparks.svg"
                    alt="Hero"
                    className="z-30 object-scale-down w-[82px] md:w-full h-[20px] my-auto"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUsPage;
