import React from 'react';
import { ComponentProps } from '../types/component-props';

const Heading31 = ({ className, children }: ComponentProps) => {
	return (
		<h3
			className={`font-semibold text-black text-[20px] leading-[24px] ${className}`}
		>
			{children}
		</h3>
	);
};

export default Heading31;
