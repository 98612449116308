import React from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from "../../icons/Check";
import FileIcon from "../../icons/File";
import DetectiveIcon from "../../icons/Detective";
import LockIcon from "../../icons/Lock";
import ShieldCheckIcon from "../../icons/ShieldCheck";

type Props = {};

const Soc2 = React.forwardRef((props: Props, ref?: any) => {
  const matches = useMediaQuery("(min-width:1300px)");

  return (
    <section id="soc2" className="relative bg-[#202020] my-[70px]">
      <p className="absolute z-10 top-[105px] left-[40px] hidden lg:block text-w-light-white text-[18px]">
        /////
      </p>
      <div
        className={` flex flex-col px-[20px] sm:px-[20px] md:px-[20px] lg:px-[120px] xl:px-[120px] py-[40px] md:py-[80px]`}
      >
        <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-1">
          <div className="flex flex-col col-span-3">
            <div className="w-full flex justify-between lg:justify-start items-start lg:items-center gap-4">
              <Heading className="text-w-light-white">
                Secure and compliant
              </Heading>
              <ShieldCheckIcon className="mt-0.5 lg:mt-0" />
            </div>
            <div className="mt-[15px] ">
              <Paragraph className="w-[100%] md:w-11/12 text-w-light-white">
                Highly regulated financial services firms trust Wokelo with
                their proprietary data. With robust protection protocols, your
                data stays shielded.
              </Paragraph>
            </div>
          </div>
          <div className="col-span-2 md:ml-3 flex w-full justify-center md:justify-end items-center py-4 mt-4 md:mt-0">
            <div
              className="w-full md:w-[350px] h-[130px] flex justify-around items-center border-l"
              style={{
                borderImage:
                  "linear-gradient(94deg, #575757 0%, #E3D6C7 100.52%) 1",
              }}
            >
              <div className="w-[50%] flex justify-center">
                <picture>
                  <source srcSet="/soc.png" type="image/webp" />
                  <img
                    src="/soc.png"
                    alt="Hero"
                    className="z-30 object-scale-down w-[78px] lg:w-full h-auto my-auto"
                  />
                </picture>
              </div>
              <p className="w-[60%] md:w-[50%] text-w-light-white text-[18px] md:text-[21px] leading-[21px] md:leading-[26px]">
                Your data is protected with SOC 2
              </p>
            </div>
          </div>
        </div>
        <div
          className={`mt-[40px] md:mt-[130px] w-full flex flex-wrap ${
            matches ? "justify-between gap-12" : "justify-between gap-12"
          }  items-start `}
        >
          <div className="w-full md:w-[340px]">
            <div className="w-full flex flex-row justify-start items-center gap-4 md:gap-5">
              <div className="">
                <div className="h-[36px] w-[36px] sm:h-[74px] sm:w-[74px] flex items-center justify-center bg-w-light-white rounded-full">
                  <LockIcon className="w-[20px] h-auto sm:w-[40px]" />
                </div>
              </div>

              <div className="md:w-[60%]">
                <h2 className="text-[24px] leading-[28px] md:text-[28px] text-w-light-white md:lending-[34px]">
                  Private workspace
                </h2>
              </div>
            </div>
            <div className="mt-5 md:mt-8">
              {["End to end encryption", "Enterprise grade security"]?.map(
                (item) => (
                  <div className="flex flex-start items-center gap-4 text-w-light-white my-2">
                    <CheckIcon />
                    <p className="text-[18px]">{item}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="w-full md:w-[340px]">
            <div className="w-full flex flex-row items-center gap-4 md:gap-5">
              <div className="">
                <div className="h-[36px] w-[36px] sm:h-[74px] sm:w-[74px] flex items-center justify-center bg-w-light-white rounded-full">
                  <DetectiveIcon className="w-[20px] h-auto sm:w-[40px]" />
                </div>
              </div>
              <div className="md:w-[40%]">
                <h2 className="text-[24px] leading-[28px] md:text-[28px] text-w-light-white md:lending-[34px]">
                  Data privacy
                </h2>
              </div>
            </div>
            <div className="mt-5 md:mt-8">
              {["Data stream disaggregation", "Controlled access"]?.map(
                (item) => (
                  <div className="flex flex-start items-center gap-4 text-w-light-white my-2">
                    <CheckIcon />
                    <p className="text-[18px]">{item}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="w-full md:w-[340px]">
            <div className="w-full flex flex-row items-center gap-4 md:gap-5">
              <div className="">
                <div className="h-[36px] w-[36px] sm:h-[74px] sm:w-[74px] flex items-center justify-center bg-w-light-white rounded-full">
                  <FileIcon className="w-[20px] h-auto sm:w-[40px]" />
                </div>
              </div>
              <div className="md:w-[60%]">
                <h2 className="text-[24px] leading-[28px] md:text-[28px] text-w-light-white md:lending-[34px]">
                  Internal data protection
                </h2>
              </div>
            </div>
            <div className="mt-5 md:mt-8">
              {["Zero-trust architecture", "PII compliant"]?.map((item) => (
                <div className="flex flex-start items-center gap-4 text-w-light-white my-2">
                  <CheckIcon />
                  <p className="text-[18px]">{item}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Soc2;
