import React from "react";
import RequestAccess from "../core/components/RequestAccessEvCharging/RequestAccess";

type Props = {};

const RequestAccessPage = (props: Props) => {
  return <RequestAccess></RequestAccess>;
};

export default RequestAccessPage;
