import React from 'react';
import { ComponentProps } from '../types/component-props';

const Heading4 = ({ className, children }: ComponentProps) => {
	return (
		<h3
			className={`font-semibold text-black text-[16px] leading-[22px] ${className}`}
		>
			{children}
		</h3>
	);
};

export default Heading4;
