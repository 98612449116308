import React from "react";
import RequestAccess from "../core/components/RequestAccess/RequestAccess";
import ConfluenceAccess from "../core/components/confluenceAccess/confluenceAccess";

type Props = {};

const Conflunece = (props: Props) => {
  return <ConfluenceAccess></ConfluenceAccess>;
};

export default Conflunece;
