import * as React from "react";

function InvestmentBanking(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        d="M2.8125 12.1875H5.625V19.6875H3.75C3.50136 19.6875 3.2629 19.7863 3.08709 19.9621C2.91127 20.1379 2.8125 20.3764 2.8125 20.625C2.8125 20.8737 2.91127 21.1121 3.08709 21.2879C3.2629 21.4638 3.50136 21.5625 3.75 21.5625H26.25C26.4986 21.5625 26.7371 21.4638 26.9129 21.2879C27.0887 21.1121 27.1875 20.8737 27.1875 20.625C27.1875 20.3764 27.0887 20.1379 26.9129 19.9621C26.7371 19.7863 26.4986 19.6875 26.25 19.6875H24.375V12.1875H27.1875C27.3915 12.1873 27.5898 12.1206 27.7525 11.9975C27.9151 11.8743 28.0331 11.7015 28.0886 11.5052C28.1442 11.309 28.1342 11.0999 28.0602 10.9099C27.9861 10.7198 27.8522 10.559 27.6785 10.452L15.491 2.95198C15.3433 2.86118 15.1734 2.81311 15 2.81311C14.8266 2.81311 14.6567 2.86118 14.509 2.95198L2.32148 10.452C2.14784 10.559 2.01385 10.7198 1.93984 10.9099C1.86582 11.0999 1.85582 11.309 1.91135 11.5052C1.96688 11.7015 2.08492 11.8743 2.24754 11.9975C2.41017 12.1206 2.60852 12.1873 2.8125 12.1875ZM7.5 12.1875H11.25V19.6875H7.5V12.1875ZM16.875 12.1875V19.6875H13.125V12.1875H16.875ZM22.5 19.6875H18.75V12.1875H22.5V19.6875ZM15 4.85042L23.8758 10.3125H6.12422L15 4.85042ZM29.0625 24.375C29.0625 24.6237 28.9637 24.8621 28.7879 25.0379C28.6121 25.2138 28.3736 25.3125 28.125 25.3125H1.875C1.62636 25.3125 1.3879 25.2138 1.21209 25.0379C1.03627 24.8621 0.9375 24.6237 0.9375 24.375C0.9375 24.1264 1.03627 23.8879 1.21209 23.7121C1.3879 23.5363 1.62636 23.4375 1.875 23.4375H28.125C28.3736 23.4375 28.6121 23.5363 28.7879 23.7121C28.9637 23.8879 29.0625 24.1264 29.0625 24.375Z"
        fill="black"
      />
    </svg>
  );
}

const InvestmentBankingIcon = React.memo(InvestmentBanking);
export default InvestmentBankingIcon;
