import React, { forwardRef, useEffect, useRef, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paragraph from "../Paragraph";
import Input from "../Input";
import { s } from "./utils";
import { AnimatePresence, LayoutGroup, motion, useInView } from "framer-motion";
import { useTheme } from "@mui/material";
import Navbar from "../Navbar";
import "./Landing4.scss";
import Vimeo from "@u-wave/react-vimeo";

type Props = {
  setLanding?: any;
  landing?: any;
};

const ANIMATION_DURATION = 2;

const Landing3 = forwardRef(({ setLanding, landing }: Props, ref?: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [email, setEmail] = useState("");
  const vidRef = useRef<HTMLVideoElement>(null);

  const [videoPaused, setVideoPaused] = useState(true);

  const handlePlayVideo = () => {
    setVideoPaused(false);
  };

  const handleStopVideo = () => {
    document
      .getElementById("video-vimeo")
      ?.querySelectorAll("iframe")
      .forEach((v) => {
        v.src = v.src;
      });

    setVideoPaused(true);
  };

  useEffect(() => {
    let timer: any = null;
    if (landing) {
      timer = setTimeout(() => {
        handlePlayVideo();
      }, (isMobile ? 1 : ANIMATION_DURATION) * 1000);
    } else {
      handleStopVideo();
    }

    return () => clearTimeout(timer);
  }, [landing]);

  return (
    <section
      id="landingPage"
      className={`w-full flex flex-col items-center ${s.heroPaddings}  overflow-hidden min-h-[calc(100vh)] bg-[#202020] color-animation`}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <LayoutGroup>
        <Navbar landing={landing} />
        <div className={`flex flex-col  w-full h-full relative`}>
          <div>
            <div className="w-full flex ">
              <h1 className="text-w-light-white font-light text-4xl md:text-7xl">
                Hyper-accelerated<br></br> research and due diligence
                <br />
                powered by Gen-AI
              </h1>
            </div>
            <div className="mt-[30px] w-full md:w-[90%]">
              <Paragraph className="text-w-light-white leading-[24px]">
                Comprehensive and credible insights for investment and strategy
                professionals
                <br />
                Enterprise grade and privacy-centered secure platform
              </Paragraph>
            </div>
            <div className="flex justify-center sm:justify-start mt-[30px]">
              <Input
                ref={ref}
                className="z-[30] border border-[rgba(227,214,199,0.30)] relative"
                inputClassName="text-[#E3D6C7] placeholder:text-[#E3D6C7]"
                placeholder="Business email"
                variant="secondary"
                value={email}
                onInput={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </div>
            <div className="min-h-[30vh]"></div>
          </div>
        </div>
      </LayoutGroup>
    </section>
  );
});

export default Landing3;
