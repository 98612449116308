import * as React from "react";

function Menu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <rect width="50" height="50" rx="25" fill="#202020" />
      <rect x="13" y="21" width="24" height="1" fill="white" />
      <rect x="13" y="28" width="24" height="1" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="49"
        height="49"
        rx="24.5"
        stroke="white"
        strokeOpacity="0.4"
      />
    </svg>
  );
}

const MenuIcon = React.memo(Menu);
export default MenuIcon;
