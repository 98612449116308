import React from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import StarIcon from "../../icons/Star";
import "./howItWorks/howItWorks.css";

type Props = {};

const HowItWorks = React.forwardRef((props: Props, ref: any) => {
  return (
    <section id="howitworks" className="relative bg-[#202020] my-[70px]">
      <div
        className={` flex flex-col px-[20px] sm:px-[20px] md:px-[20px] lg:px-[120px] xl:px-[120px] py-[40px] md:py-[80px]`}
      >
        <div className="flex justify-between items-center text-w-light-black">
          <Heading className="text-w-light-white">How it works</Heading>
          <p className="text-[18px] text-w-light-white">/////</p>
        </div>
        <div className="">
          <Paragraph className="mt-2 text-w-light-white">
            Select your sources, define analysis, and you're presented with a
            refined output like magic
          </Paragraph>
        </div>
        {/* <div className="my-[60px] hidden lg:grid lg:grid-cols-5 gap-4">
          <div className="flex flex-between">
            <p className="text-w-light-white text-[26px]">
              Comprehensive Data Curation
            </p>
          </div>
          <div className="flex justify-end mr-4">
            <StarIcon />
          </div>
          <div className="">
            <ul className="md:list-disc list-outside px-1">
              <li className="text-w-light-white">
                <p className="text-[16px] leading-[19px]">
                  {" "}
                  Leverage Wokelo’s extensive library of 500+ pre-integrated
                  public and premium datasets{" "}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <ul className="md:list-disc list-outside px-1">
              <li className="text-w-light-white">
                <p className="text-[16px] leading-[19px]">
                  {" "}
                  Connect your internal datasets or upload multiple files for
                  secure data synthesis{" "}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <ul className="md:list-disc list-outside px-1">
              <li className="text-w-light-white ">
                <p className="text-[16px] leading-[19px]">
                  {" "}
                  Up-to-date and live insights with citations from tier-1
                  sources{" "}
                </p>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="mt-[60px] hidden lg:block aligncenter w-[75%]">
          <picture className="relative">
            <source srcSet="/TreeV3.png" type="image/webp" />
            <img
              src="/TreeV3.png"
              alt="Hero"
              className="z-30 relative object-scale-down w-full h-auto my-auto"
            />
            <div className="glowing-circle"></div>
          </picture>
        </div>
        <div className="mt-[100px] hidden lg:block">
          <div className="grid grid-cols-3 gap-8">
            <div className="flex justify-end gap-8 items-start justify-center">
              <div>
                {" "}
                <StarIcon className="w-[19px]" />
              </div>
              <div>
                <ul className="list-disc list-outside text-w-light-white text-[14px] leading-[15px] md:text-[18px] md:leading-[25px]">
                  {[
                    "500+ pre-integrated datasets",
                    "Connect internal datasets",
                    "Real-time processing",
                  ].map((q) => (
                    <li key={q} className="mb-4">
                      {q}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex justify-end gap-8 items-start justify-center">
              <div>
                {" "}
                <StarIcon className="w-[19px]" />
              </div>
              <div>
                <ul className="list-disc list-outside text-w-light-white text-[14px] leading-[15px] md:text-[18px] md:leading-[25px]">
                  {[
                    "Fact-based without hallucination",
                    "High fidelity proprietary models",
                    "Human-like synthesis, 24/7",
                  ].map((q) => (
                    <li key={q} className="mb-4">
                      {q}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex justify-end gap-8 items-start justify-center">
              <div>
                {" "}
                <StarIcon className="w-[19px]" />
              </div>
              <div>
                <ul className="list-disc list-outside text-w-light-white text-[14px] leading-[15px] md:text-[18px] md:leading-[25px]">
                  {[
                    "Executive ready presentations",
                    "Automate research workflows",
                    "Configure templates",
                  ].map((q) => (
                    <li key={q} className="mb-4">
                      {q}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 block lg:hidden">
          <div className="flex justify-center">
            <picture className="relative">
              <source srcSet="/TreeV2Mob.png" type="image/webp" />
              <img
                src="/TreeV2Mob.png"
                alt="Hero"
                className="z-30 relative w-[80vw]"
              />
              <div className="glowing-circle-mobile"></div>
            </picture>
          </div>
          <div className="mt-8 w-full mb-4">
            <div className="w-full flex justify-center">
              <div className="w-[100%]">
                <p className="text-w-light-white font-bold text-[18px] text-center my-3">
                  Comprehensive data curation
                </p>
                <Paragraph className="text-w-light-white text-center my-3">
                  {" "}
                  500+ pre-integrated datasets
                </Paragraph>
                <Paragraph className="text-w-light-white text-center my-3">
                  Connect internal datasets
                </Paragraph>
                <Paragraph className="text-w-light-white text-center my-3">
                  Real-time processing
                </Paragraph>
              </div>
            </div>
            <div className="flex justify-center my-8">
              <StarIcon className="w-[19px]" />
            </div>
            <div className="w-full flex justify-center">
              <div className="w-[80%]">
                <p className="text-w-light-white font-bold text-[18px] text-center my-3">
                  Proprietary cognitive engine
                </p>

                <Paragraph className="text-w-light-white text-center my-3">
                  Fact-based without hallucination
                </Paragraph>
                <Paragraph className="text-w-light-white text-center my-3">
                  High fidelity proprietary models
                </Paragraph>
                <Paragraph className="text-w-light-white text-center my-3">
                  Human-like synthesis, 24/7
                </Paragraph>
              </div>
            </div>
            <div className="flex justify-center my-8">
              <StarIcon className="w-[19px]" />
            </div>
            <div className="w-full flex justify-center">
              <div className="w-[80%]">
                <p className="text-w-light-white font-bold text-[18px] text-center my-3">
                  Customized reports
                </p>
                <Paragraph className="text-w-light-white text-center my-3">
                  Executive ready presentations
                </Paragraph>
                <Paragraph className="text-w-light-white text-center my-3">
                  Automate research workflows
                </Paragraph>
                <Paragraph className="text-w-light-white text-center my-3">
                  Configure templates
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default HowItWorks;
