import { ButtonHTMLAttributes } from "react";
import { buttonVariants } from "./Button";
import React from "react";

export type IconButtonVariant =
  | "primary"
  | "transparent"
  | "outlined"
  | "secondary";

// const buttonVariants: { [key in IconButtonVariant]: string } = {
// 	primary:
// 		'bg-w-purple text-white shadow-sm rounded-[4px] shadow-gray-300/30 hover:shadow-md',
// 	secondary:
// 		'bg-black text-white shadow-sm rounded-[4px] shadow-gray-300/30 hover:shadow-md',
// 	transparent: 'text-w-purple rounded-[4px] hover:bg-gray-200',
// 	outlined:
// 		'border border-w-purple rounded-[4px] text-w-purple hover:shadow-md hover:shadow-gray-300/30',
// };

export type ButtonProps = {
  variant?: IconButtonVariant;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = ({
  variant,
  className,
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={`transition-all truncate flex flex-row items-center justify-center font-semibold text-[13px] leading-[16px] px-[11px] py-[9px]
			 ${props.disabled ? "cursor-not-allowed" : ""} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default IconButton;
