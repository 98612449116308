import React, { useState } from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { AnimatePresence, motion } from "framer-motion";
import {
  customers,
  s,
  swipeConfidenceThreshold,
  swipePower,
  variants,
} from "./utils";
import { useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-multi-carousel";
import "./WhyCustomerLoveWokeloCarousel.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1300 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1100 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1100, min: 750 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
  },
};

type Props = {
  title?: string;
  subtitle?: string;
  titleClassName?: any;
  subTitleClassName?: any;
};

const WhyCustomerLovesWokeloCarouselEvChargingUs = React.forwardRef(
  (
    {
      titleClassName,
      subTitleClassName,
      title = "Why customers love Wokelo",
      subtitle = "Leading professionals are leveraging Wokelo to get ahead of the curve",
    }: Props,
    ref?: any
  ) => {
    // const [[page, direction], setPage] = useState([0, 0]);
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    // const paginate = (newDirection: number) => {
    //   if (page + newDirection <= 0) {
    //     setPage([customers?.length, newDirection]);
    //   } else {
    //     setPage([page + newDirection, newDirection]);
    //   }
    // };

    return (
      <section className={`flex flex-col ${s.heroPaddingsEvCharging}`}>
        <div>
          <div className="flex justify-between items-start text-w-light-black">
            <Heading className={`${titleClassName}`}>{title}</Heading>
            <p className="mt-4 text-[18px]">/////</p>
          </div>
          <div className=" text-w-light-gray">
            <Paragraph
              className={`mt-5 w-full sm:w-[70%] md:w-[90%] ${subTitleClassName}`}
            >
              {subtitle}
            </Paragraph>
          </div>
          <div className="my-[60px] relative">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={true}
              arrows={isDesktop}
              className="relative testimonials-carousel py-1"
              //   className="gd-carousel"
            >
              {customers?.map((customer, index: any) => {
                return (
                  <div className="ml-0 lg:mx-5 w-full min-h-0 md:h-auto text-w-light-black">
                    <div className="border w-full h-full p-5 md:p-8 border-[#858585]">
                      <div className="flex justigy-between items-center w-full">
                        <div className="w-full flex flex-row items-center gap-5">
                          <div>
                            <div className="h-[74px] w-[74px] flex items-center justify-center text-white bg-w-cream rounded-full">
                              <img
                                src={customer?.profileImg}
                                alt="Hero"
                                className="z-30 object-scale-down w-full h-auto my-auto profile rounded-full"
                              />
                            </div>
                          </div>
                          <div className="">
                            <h2 className="w-full text-[24px] leading-[29px] md:text-[32px] md:leading-[34px] text-w-light-black">
                              {customer?.name}
                            </h2>
                            <p className="text-[14px] leading-[16px] md:text-[18px] md:leading-[21px] text-[#737373]">
                              {customer?.role}
                            </p>
                          </div>
                        </div>

                        <div className={`h-[84px] w-[90px] items-center justify-center hidden xl:flex`} style={{paddingLeft:`${customer.pad + 6}px`,paddingTop:`${customer.pad}px`, paddingRight:`${customer.pad}px`, paddingBottom:`${customer.pad}px`}}>
                          <picture>
                            <source srcSet={customer?.img} type="image/webp" />
                            <img
                              src={customer?.img}
                              alt="Hero"
                              className="z-30 object-scale-down w-full h-auto my-auto profile"
                            />
                          </picture>
                        </div>
                      </div>
                      <hr className="mt-6 mb-4 md:mb-8 md:mt-8 border-t-[1px] border-t-[#727272]"></hr>
                      <div>
                        <p className="my-2 text-[#141414] text-[16px] leading-[23px]">
                          {customer?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    );
  }
);

export default WhyCustomerLovesWokeloCarouselEvChargingUs;
