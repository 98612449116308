import * as React from "react";

function Wokelo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <picture>
      <source srcSet={"/logo_black.svg"} type="image/webp" />
      <img
        src={"/logo_black.svg"}
        alt="Hero"
        className="z-30 w-[254px] h-[148px]"
      />
    </picture>
  );
}

const WokeloIcon = React.memo(Wokelo);
export default WokeloIcon;
