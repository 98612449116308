import React from "react";
import { ComponentProps } from "../types/component-props";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = ({ children, className, landing = false }: ComponentProps) => {
  return (
    <>
      {console.log(landing)}
      <div
        className={`flex flex-col h-auto ${
          landing ? null : "bg-[#202020]"
        }  ${className}`}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Navbar landing={landing} />
      </div>
      <div className={`h-auto`} style={{ paddingTop: 0, paddingBottom: 0 }}>
        {children}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
