import { useEffect, useState } from "react";

/**
 * This React hook helps to limit that the component is re-rendered too many times.
 *
 * @param value is the value to be debounced
 * @param delay is the delay between calls
 * @returns the debounced value
 */
function useDebounce(value: any, delay: number = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return debouncedValue;
}

export default useDebounce;
