import React from "react";
import { ComponentProps } from "../types/component-props";

const Heading = ({ className, children }: ComponentProps) => {
  return (
    <h1
      className={`${className} text-[40px] md:text-[55px] leading-[48px] md:leading-[66px]`}
    >
      {children}
    </h1>
  );
};

export default Heading;
