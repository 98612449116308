import React, { useState, useEffect } from "react";
import { s } from "../Home/utils";
import Navbar from "../Navbar";
import Paragraph from "../Paragraph";
import Heading from "../Heading";
import "./Form.scss";
import useDebounce from "../../hooks/useDebounce";
import { emailsBlock } from "../../utils/blackListEmails";
import { SubmitHandler, useForm } from "react-hook-form";
import { Api } from "../../hooks/Api";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";

const ThankYouHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <section
      id="landingPage"
      className={`w-full flex flex-col items-center ${s.heroPaddings} overflow-auto md:min-h-[calc(55vh)] bg-[#202020] color-animation thankyou-header`}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
      }}
    >
      <Navbar landing={false} addPaddingRight={true} />
      <div className="w-full flex flex-col md:grid grid-cols-1 md:grid-cols-5 pt-5 gap-3 overflow-hidden h-full flex-grow">
        <div className="flex flex-col col-span-1 md:col-span-3 text-[#fff] pr-[25px] md:pr-[0px]">
          <h1 className="text-4xl md:text-6xl leading-[90%] mt-7 font-light">
            Thank You!
          </h1>
          <p className="text-xl md:text-2xl font-light leading-5 md:leading-7 mt-5 w-[100%] md:w-[80%] lg:w-[90%] tracking-[0.36]">
            Your request has been received and a<br />
            member of our team will be in touch soon!
          </p>
        </div>

        <div className="col-span-1 md:col-span-2 h-full w-full flex flex-col md:justify-end mt-11 mb-11 md:mb-0 md:mt-0">
          <img
            className="block w-auto min-h-0"
            src="/graphics/ThankYou.png"
          ></img>
        </div>
      </div>
    </section>
  );
};

export default ThankYouHeader;
