import * as React from "react";

function Graph(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M5 16.1053H9.66667V27H5V16.1053ZM21.3333 11.2632H26V27H21.3333V11.2632ZM13.1667 4H17.8333V27H13.1667V4Z"
        fill="#B6BEB5"
      />
    </svg>
  );
}

const GraphIcon = React.memo(Graph);
export default GraphIcon;
