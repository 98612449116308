import React, { useState, useEffect } from "react";
import Paragraph from "../Paragraph";
import { s } from "./utils";
import { AnimatePresence, LayoutGroup, motion, useInView } from "framer-motion";
import { useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../Navbar";
import "./Landing4.scss";
import Vimeo from "@u-wave/react-vimeo";

const ANIMATION_DURATION = 2;

const opacity = {
  1: 1,
  2: 0.6,
  3: 0.25,
  4: 0,
};

const gap = {
  1: "60vw",
  2: "57vw",
  3: "52vw",
  4: "47vw",
};

type Props = {
  setLanding?: any;
  landing?: any;
  scrollHeight?: any;
  setScrollHeight?: any;
  leftAligned?: boolean;
};

function scrollTo55Percent() {
  const viewportHeight = window.innerHeight;
  const scrollDistance = 0.6 * viewportHeight;
  window.scrollTo({
    top: scrollDistance,
    behavior: "smooth",
  });
}

const TrustedByCopy = React.forwardRef(
  (
    {
      setLanding,
      landing,
      scrollHeight,
      setScrollHeight,
      leftAligned = false,
    }: Props,
    ref?: any
  ) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [videoPaused, setVideoPaused] = useState(true);

    const handlePlayVideo = () => {
      setVideoPaused(false);
    };

    const handleStopVideo = () => {
      document
        .getElementById("video-vimeo")
        ?.querySelectorAll("iframe")
        .forEach((v) => {
          v.src = v.src;
        });

      setVideoPaused(true);
    };

    useEffect(() => {
      if (scrollHeight == 4) {
        if (videoPaused) {
          handlePlayVideo();
        }
      } else if (!videoPaused) {
        handleStopVideo();
      }
    }, [scrollHeight]);

    const minHeight = `min-h-[calc(${gap[scrollHeight]}-20vh)]`;

    return (
      <section
        className={`w-full flex flex-col relative ${s.heroPaddings}`}
        style={{ paddingTop: 0, paddingBottom: leftAligned ? 60 : 0 }}
      >
        {!leftAligned && (
          <div
            className={`relative video-main flex-grow w-full self-stretch cursor-pointer mt-10 ${
              isMobile ? "min-h-[calc(70vw-20vh)]" : "min-h-[calc(50vw-20vh)]"
            }`}
            style={{
              width: "100%",
              top: "-20vh",
            }}
          >
            <img
              src={isMobile ? "/videoBgMobile.png" : "/videoBg.png"}
              alt="Hero"
              className={`absolute z-20 flex justify-center videoBg videoBg-${
                isMobile ? "mob" : scrollHeight
              }`}
              onClick={() => {
                scrollTo55Percent();
              }}
              style={{
                top: "0%",
                left: "50%",
                transform: "translate(-50%, -20%)",
                opacity: opacity[scrollHeight],
              }}
            />
            <div
              className="absolute flex justify-center w-full h-[full] mx-auto"
              style={{
                top: "0%",
                left: "50%",
                transform: `${
                  landing
                    ? "translate(-50%, 2%)"
                    : isMobile
                    ? "translate(-50%, 5%)"
                    : "translate(-50%, -4%)"
                }`,
              }}
            >
              <div
                id="video-container"
                className={`video-container video-container-h-${
                  isMobile ? 1 : scrollHeight
                } pb-1`}
              >
                <img
                  className="ipadDesktop"
                  src="/AppleiPadProMin.png"
                  alt=""
                />
                <div className="video-content">
                  <Vimeo
                    id="video-vimeo"
                    video="866236942"
                    paused={videoPaused}
                    start={0}
                    loop
                    muted={true}
                    showPortrait
                    controls={false}
                  />
                </div>
                <hr
                  className={`absolute bottom-line w-[85vw] ${
                    isMobile ? "mt-[53.64vw]" : ""
                  } bg-[black] z-1000`}
                ></hr>
              </div>
            </div>
          </div>
        )}
        <div
          className={`${
            leftAligned
              ? "grid mt-10 grid-cols-1 md:grid-cols-5 min-h-[25vh]"
              : "flex flex-col"
          }`}
        >
          <div
            className={`flex justify-center mb-4 ${
              leftAligned ? "col-span-1 md:col-span-3" : ""
            }`}
          >
            <div className="w-full">
              <Paragraph
                className={`${
                  leftAligned ? "text-center md:text-left" : "text-center"
                } text-[#525252]`}
              >
                Trusted by industry leaders
              </Paragraph>
              <div
                className={`w-full hidden md:flex flex-row flex-wrap  ${
                  leftAligned ? "justify-start" : "justify-center"
                } items-center mt-6 ipadGrid`}
              >
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source
                      srcSet="/client/logos/seven.svg"
                      type="image/webp"
                    />
                    <img
                      src="/client/logos/seven.svg"
                      alt="776"
                      className="z-30 object-scale-down w-[34px] h-auto my-auto"
                    />
                  </picture>
                </div>
                
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/client/logos/pale.svg" type="image/webp" />
                    <img
                      src="/client/logos/pale.svg"
                      alt="Pale"
                      className="z-30 object-scale-down w-full h-auto my-auto"
                    />
                  </picture>
                </div>
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/untapped.svg" type="image/webp" />
                    <img
                      src="/untapped.svg"
                      alt="untapped"
                      className="z-30 object-scale-down w-full h-auto my-auto"
                    />
                  </picture>
                </div>
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/client/logos/snocap.svg" type="image/webp" />
                    <img
                      src="/client/logos/snocap.svg"
                      alt="snocap"
                      className="z-30 object-scale-down w-[86px] h-auto my-auto"
                    />
                  </picture>
                </div>
                <div
                  className={leftAligned ? "mx-3 my-3 md:mx-4 hidden" : "mx-6"}
                >
                  <picture>
                    <source
                      srcSet="/client/logos/gratia.svg"
                      type="image/webp"
                    />
                    <img
                      src="/client/logos/gratia.svg"
                      alt="Hero"
                      className="z-30 object-scale-down w-[60px] h-auto my-auto"
                    />
                  </picture>
                </div>
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/guggengeim.svg" type="image/webp" />
                    <img
                      src="/guggengeim.svg"
                      alt="Hero"
                      className="z-30 object-scale-down w-full h-auto my-auto"
                    />
                  </picture>
                </div>
                
              </div>
              <div
                className={`w-full md:hidden mt-10 ${
                  leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"
                }`}
              >
                <div className="w-full flex   items-center justify-center gap-10 flex-wrap">
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source
                      srcSet="/client/logos/seven.svg"
                      type="image/webp"
                    />
                    <img
                      src="/client/logos/seven.svg"
                      alt="776"
                      className="z-30 object-scale-down w-[34px] h-auto my-auto"
                    />
                  </picture>
                </div>
                
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/client/logos/pale.svg" type="image/webp" />
                    <img
                      src="/client/logos/pale.svg"
                      alt="Pale"
                      className="z-30 object-scale-down w-full h-auto my-auto"
                    />
                  </picture>
                </div>
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/untapped.svg" type="image/webp" />
                    <img
                      src="/untapped.svg"
                      alt="untapped"
                      className="z-30 object-scale-down w-full h-auto my-auto"
                    />
                  </picture>
                </div>
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/client/logos/snocap.svg" type="image/webp" />
                    <img
                      src="/client/logos/snocap.svg"
                      alt="snocap"
                      className="z-30 object-scale-down w-[86px] h-auto my-auto"
                    />
                  </picture>
                </div>
                <div
                  className={leftAligned ? "mx-3 my-3 md:mx-4 hidden" : "mx-6"}
                >
                  <picture>
                    <source
                      srcSet="/client/logos/gratia.svg"
                      type="image/webp"
                    />
                    <img
                      src="/client/logos/gratia.svg"
                      alt="Hero"
                      className="z-30 object-scale-down w-[60px] h-auto my-auto"
                    />
                  </picture>
                </div>
                <div className={leftAligned ? "mx-3 my-3 md:mx-4" : "mx-6"}>
                  <picture>
                    <source srcSet="/guggengeim.svg" type="image/webp" />
                    <img
                      src="/guggengeim.svg"
                      alt="Hero"
                      className="z-30 object-scale-down w-full h-auto my-auto"
                    />
                  </picture>
                </div>
                </div>
              </div>
            </div>
          </div>
          {!leftAligned && <hr className="mt-10"></hr>}
        </div>
      </section>
    );
  }
);

export default TrustedByCopy;
