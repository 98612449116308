import React, { useRef, useState, useEffect } from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import useMediaQuery from "@mui/material/useMediaQuery";
import { s } from "./utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Input from "../Input";
import { motion, useInView } from "framer-motion";
import CorporatestrategyIcon from "../../icons/Corporatestrategy";
import InvestmentBankingIcon from "../../icons/InvestmentBanking";
import ManagementConsultingIcon from "../../icons/ManagementConsulting";
import PrivateEquityIcon from "../../icons/PrivateEquity";
import VentureCapitalIcon from "../../icons/VentureCapital";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./solutions/solutions.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1300 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1100 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1100, min: 750 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 2,
  },
};

// Style component abstractions
type solutionProps = {
  id: number;
  title: string;
  attributes: string[];
  img: JSX.Element;
  open: boolean;
};

const solutions: solutionProps[] = [
  {
    id: 1,
    title: "Private Equity",
    attributes: [
      "Due diligence",
      "Data room synthesis",
      "Valuation and analysis",
      "Portfolio monitoring",
    ],
    img: <PrivateEquityIcon />,
    open: true,
  },
  {
    id: 2,
    title: "Investment Banking",
    attributes: [
      "Investment theses",
      "Equity research",
      "Mergers & Acquisitions",
      "Hedge fund research",
    ],
    img: <InvestmentBankingIcon />,
    open: false,
  },
  {
    id: 3,
    title: "Corporate Strategy",
    attributes: [
      "Corporate development",
      "Competitive intelligence",
      "New product strategy",
      "Signals and trends",
    ],
    img: <CorporatestrategyIcon />,
    open: false,
  },
  {
    id: 4,
    title: "Venture Capital",
    attributes: [
      "Company diligence",
      "Investment memos",
      "Sector research",
      "Investment theses",
    ],
    img: <VentureCapitalIcon />,
    open: false,
  },
  {
    id: 5,
    title: "Management Consulting",
    attributes: [
      "Market research",
      "Company research",
      "Mergers & Acquisitions",
      "Industry benchmarking",
    ],
    img: <ManagementConsultingIcon />,
    open: false,
  },
];

type Props = {};

const variants = [
  {
    hidden: { opacity: 0, x: -283 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 40,
        damping: 20,
        duration: 2,
        //delay:0
      },
    },
  },
  {
    hidden: { opacity: 0, x: -566 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 40,
        damping: 20,
        duration: 4,
        //delay:1
      },
    },
  },
  {
    hidden: { opacity: 0, x: -849 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 40,
        damping: 20,
        duration: 6,
        //delay:2
      },
    },
  },
  {
    hidden: { opacity: 0, x: -1132 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 40,
        damping: 20,
        duration: 8,
        //delay:3
      },
    },
  },
  {
    hidden: { opacity: 0, x: -1415 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 20,
        duration: 10,
        //delay:4
      },
    },
  },
];

const Solutions = React.forwardRef((props: Props, ref?: any) => {
  const matches = useMediaQuery("(min-width:1300px)");
  const [email, setEmail] = useState("");

  const [solutionsData, setSolutionsData] =
    useState<solutionProps[]>(solutions);

  const handleOpenSolutions = (id: number, open: boolean) => {
    let updated: solutionProps[] = [];
    solutions?.map((items) => {
      if (items?.id === id) {
        updated.push({
          id: items?.id,
          title: items?.title,
          attributes: items?.attributes,
          img: items?.img,
          open: !open,
        });
      } else {
        updated.push({
          id: items?.id,
          title: items?.title,
          attributes: items?.attributes,
          img: items?.img,
          open: false,
        });
      }
    });
    setSolutionsData(updated);
  };

  // useInView hook usage
  const animationref = useRef(null);
  const isInView = useInView(animationref);

  const [triggerAnimation, setTriggerAnimation] = useState(false);

  useEffect(() => {
    if (!triggerAnimation && isInView) {
      setTriggerAnimation(true);
    }
  }, [isInView]);

  return (
    <section
      ref={animationref}
      id="solutions"
      className={`flex flex-col ${s.heroPaddings}`}
    >
      <div>
        <div className="flex justify-between text-w-light-black">
          <Heading>Solutions</Heading>
          <p className="text-[18px]">/////</p>
        </div>
        <div>
          <Paragraph className="mt-2 md:mt-3 text-w-light-gray">
            Partnering with leading data-driven investment and strategy firms
          </Paragraph>
        </div>
        <div
          ref={animationref}
          className={`my-[60px] w-full flex flex-row ${
            matches ? "justify-between" : "justify-center gap-4"
          }  flex-wrap md:hidden`}
        >
          {solutionsData?.map((items: solutionProps, index: any) => {
            return (
              <motion.div
                variants={variants[index]}
                initial="hidden"
                animate={triggerAnimation ? "show" : "hidden"}
                key={items?.id}
                className={`border-[0.5px] border-[#858585] bg-[#F5F5F5] flex flex-col justify-around  w-full md:w-[212px] lg:[228px] ${
                  items?.open ? "h-[190px]" : "h-auto"
                } md:h-[250px] lg:h-[290px] py-4 px-4 text-w-light-black`}
              >
                <div className="flex justify-between items-center h-[17%]">
                  <div className="flex flex-row-reverse md:flex-row justify-end gap-3 md:justify-between items-center w-full">
                    <h5 className="font-medium md:w-[60%] text-[18px] leading-[22px]">
                      {items?.title}
                    </h5>
                    <div className="flex justify-end md:w-[40%] ">
                      {items?.img}
                    </div>
                  </div>
                  <div className="md:hidden">
                    <button
                      onClick={() => {
                        handleOpenSolutions(items?.id, items?.open);
                      }}
                    >
                      {items?.open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </button>
                  </div>
                </div>
                <div className={`${items?.open ? "" : "hidden"} md:block`}>
                  <hr className="border-t-[1px] border-t-#858585"></hr>
                </div>
                <div className={`${items?.open ? "" : "hidden"} md:block`}>
                  <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
                    {items?.attributes.map((item: string) => (
                      <p className="text-[14px] md:text-[16px] text-w-light-black">
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        <div
          className={`my-[60px] w-full hidden ${
            matches ? "justify-between" : "justify-center gap-4"
          }  flex-wrap md:block relative pb-8`}
        >
          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            className="gd-carousel"
          >
            {solutionsData?.map((items: solutionProps, index: any) => {
              return (
                <div
                  key={items?.id}
                  className={`border-[0.5px] border-[#858585] bg-[#F5F5F5] flex flex-col justify-around  w-full mr-[5px] lg:[228px] ${
                    items?.open ? "h-[190px]" : "h-auto"
                  } md:h-[250px] lg:h-[290px] py-4 px-4 text-w-light-black`}
                >
                  <div className="flex justify-between items-center h-[17%]">
                    <div className="flex flex-row-reverse md:flex-row justify-end gap-3 md:justify-between items-center w-full">
                      <h5 className="font-medium md:w-[60%] text-[18px] leading-[22px]">
                        {items?.title}
                      </h5>
                      <div className="flex justify-end md:w-[40%] ">
                        {items?.img}
                      </div>
                    </div>
                    <div className="md:hidden">
                      <button
                        onClick={() => {
                          handleOpenSolutions(items?.id, items?.open);
                        }}
                      >
                        {items?.open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={`${items?.open ? "" : "hidden"} md:block`}>
                    <hr className="border-t-[1px] border-t-#858585"></hr>
                  </div>
                  <div className={`${items?.open ? "" : "hidden"} md:block`}>
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
                      {items?.attributes.map((item: string) => (
                        <p className="text-[14px] md:text-[16px] text-w-light-black">
                          {item}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="flex justify-center my-4">
          <Input
            className="border-[1px] border-[rgba(52,52,52,0.40)] bg-transparent"
            placeholder="Business email"
            inputClassName={"text-w-black placeholder:text-w-black"}
            value={email}
            onInput={(e) => {
              setEmail(e.currentTarget.value);
            }}
            variant="primary"
          />
        </div>
      </div>
    </section>
  );
});

export default Solutions;
