import * as React from "react";

function File(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M23.3333 15V6.66671H8.33333V33.3334H18.4272C18.973 34.0285 19.6338 34.6412 20.3933 35.1414L22.71 36.6667H6.65567C5.74158 36.6667 5 35.9267 5 35.0137V4.98637C5 4.09222 5.74783 3.33337 6.67035 3.33337H24.9947L35 13.3334V15H23.3333ZM20 18.3334H35V28.2484C35 29.8995 34.1645 31.4414 32.7735 32.3574L27.5 35.8297L22.2265 32.3574C20.8355 31.4414 20 29.8995 20 28.2484V18.3334ZM23.3333 28.2484C23.3333 28.7754 23.6027 29.2724 24.0597 29.5734L27.5 31.8387L30.9403 29.5734C31.3973 29.2724 31.6667 28.7754 31.6667 28.2484V21.6667H23.3333V28.2484Z"
        fill="#343434"
      />
    </svg>
  );
}

const FileIcon = React.memo(File);
export default FileIcon;
