import React from 'react';
import { ComponentProps } from '../types/component-props';

const Paragraph1 = ({ className, children }: ComponentProps) => {
	return (
		<p
			className={`font-normal text-black text-[14px] leading-[21px] ${className}`}
		>
			{children}
		</p>
	);
};

export default Paragraph1;
