import * as React from "react";

function Map(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_706_2105)">
        <path
          d="M14.7167 13.0477L10.0026 17.7617L5.28856 13.0477C2.68506 10.4442 2.68506 6.22311 5.28856 3.61962C7.89205 1.01612 12.1132 1.01612 14.7167 3.61962C17.3201 6.22311 17.3201 10.4442 14.7167 13.0477ZM4.16927 18.3337H15.8359V20.0003H4.16927V18.3337Z"
          fill="#343434"
        />
      </g>
      <defs>
        <clipPath id="clip0_706_2105">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MapIcon = React.memo(Map);
export default MapIcon;
