import * as React from "react";

function ManagementConsulting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      {...props}
    >
      <path
        d="M19.8244 14.7169L19.845 14.7316L19.867 14.7441C20.7095 15.2256 21.3284 15.9561 21.8409 16.8531C22.3227 17.6962 22.45 18.5456 22.45 19.44V22.4701C22.3672 22.4997 22.2677 22.5341 22.1521 22.5722C21.744 22.7069 21.1347 22.8882 20.3409 23.0702C18.7579 23.4329 16.4419 23.7979 13.5258 23.8L13.87 23.4214L15.22 21.9364L15.35 21.7933V21.6V17.55V17.4402L15.304 17.3405L14.6355 15.8919L16.414 14.0022C16.8617 14.0112 17.4722 14.0492 18.079 14.1457C18.8087 14.2618 19.4521 14.4509 19.8244 14.7169ZM4.84799 22.5722C4.73238 22.5341 4.63294 22.4997 4.55005 22.4701V19.44C4.55005 18.4167 4.67526 17.6999 5.15917 16.8531C5.69304 15.9188 6.17761 15.213 6.99812 14.7441L7.02008 14.7316L7.04067 14.7169C7.41246 14.4513 8.05529 14.262 8.80128 14.146C9.43733 14.047 10.0907 14.0095 10.5946 14.0016L12.4784 15.8855L11.7098 17.313L11.65 17.424V17.55V21.6V21.7933L11.7801 21.9364L13.1301 23.4214L13.4743 23.8C10.5582 23.7979 8.24222 23.4329 6.65924 23.0702C5.86543 22.8882 5.25607 22.7069 4.84799 22.5722ZM10.3495 7.89085C10.1629 6.6469 10.4165 5.49502 10.9775 4.66827C11.5314 3.85203 12.3931 3.33502 13.5 3.33502C14.6177 3.33502 15.4767 3.82759 16.0249 4.62111C16.5809 5.42586 16.8382 6.57487 16.6503 7.8927C16.4562 9.18501 16.041 10.2439 15.4834 10.9673C14.9319 11.6828 14.2574 12.055 13.5 12.055C12.7427 12.055 12.0682 11.6828 11.5167 10.9673C10.9588 10.2436 10.5435 9.184 10.3495 7.89085Z"
        fill="#111111"
        stroke="black"
      />
    </svg>
  );
}

const ManagementConsultingIcon = React.memo(ManagementConsulting);
export default ManagementConsultingIcon;
