import React, { useEffect, useState } from "react";
import Footer from "../core/components/Footer";
import WokeloIcon from "../core/icons/Wokelo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { emailsBlock } from "../core/utils/blackListEmails";
import useDebounce from "../core/hooks/useDebounce";
import { SubmitHandler, useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { Api } from "../core/hooks/Api";

type Props = {};
type Inputs = {
    username: string;
};


const RequestConference = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [username, setUsername] = useState<string | null>("");
    const source: string = searchParams.get('source') as string
    const [emailError, setEmailError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { handleSubmit } = useForm<Inputs>();
    const debouncedEmail = useDebounce(username, 500);
    const [formError, setFormError] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const sourceToImage = {
        'acg': 'https://wklogo.blob.core.windows.net/user-uploaded/super-return.png',
        // 'acg': 'https://wklogo.blob.core.windows.net/user-uploaded/acg.png',
        'envest': 'https://wklogo.blob.core.windows.net/user-uploaded/envest.png',
        'super-return': 'https://wklogo.blob.core.windows.net/user-uploaded/super-return.png'
    }
    const emailArr = username?.split("@");

    useEffect(() => {
        const emailArr = debouncedEmail?.split("@");

        if (emailArr && emailArr?.length >= 2) {
            if (emailsBlock.includes(emailArr[1])) {
                setEmailError("Please use your corporate email address");
            } else {
                setEmailError("");
            }
        }
    }, [debouncedEmail]);


    const onSubmit: SubmitHandler<Inputs> = async () => {
        setFormError("");
        setLoading(true);

        const payload = {
            username: username,
            source: source,
        };
        const emailArr = username?.split("@");

        if (emailArr && emailArr?.length >= 2) {
            if (emailsBlock.includes(emailArr[1])) {
                setEmailError("Please use your corporate email address");
                setLoading(false);
                return
            } else {
                setEmailError("");
            }
        }

        // setModalCalendly(true);
        await Api.requestAccess(payload)
            .then((res) => {
                if (res?.message) {
                    // setModalCalendly(true);
                    // downloadReport()
                    navigate("/thank-you");
                    setLoading(false);
                } else {
                    // setModalCalendly(true);
                    setFormError("'Something Went Wrong, Please try again..'");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.error(err);
                // setModalCalendly(true);
                setFormError("'Something Went Wrong, Please try again..'");
                setLoading(false);
            });
    };


    return (
        <div className="flex flex-col relative px-4 md:px-10 py-5 h-screen">
            <div className="flex items-center justify-between">
                <img
                    src={"https://wkemails.blob.core.windows.net/fe-application/Wokelo-Logo.svg"}
                    alt="Hero"
                    className="z-30 object-scale-down w-[70px] md:w-[100px] h-auto"
                />
            </div>

            <div className=" w-full flex justify-center items-center h-full flex-col" >
            {sourceToImage[source] ? <img
                    src={sourceToImage[source]}
                    alt="Conference"
                    className="z-30 object-scale-down w-[170px] md:w-[170px] max-h-[220px] mb-5 h-auto"
                /> :<div style={{
                fontSize: '24px',
                fontWeight: 600
                }}>Request a demo
            </div>}


                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col px-6 bg-[#fff] rounded-3xl pt-11 pb-5 request-access-form max-w-[450px] w-full my-8"
                    style={{ border: "1px solid #E6E6E6" }}
                >
                    <div className="mb-4">
                        <input
                            type="email"
                            className=" w-full text-sm data-hj-allow"
                            placeholder="Business email"
                            onInput={(e) => setUsername(e.currentTarget.value)}
                            value={username ?? ""}
                            data-hj-allow={true}
                        />
                        {emailError && (
                            <span
                                className=" text-red-500 text-xs"
                                style={{ lineHeight: "10px" }}
                            >
                                {emailError}
                            </span>
                        )}
                    </div>

                    <div>
                        {formError && (
                            <span
                                className="text-red-500 text-xs"
                                style={{ lineHeight: "10px" }}
                            >
                                {formError}
                            </span>
                        )}
                    </div>

                    <div className="flex justify-center mt-[30px] mb-[20px]">
                        <button
                            className="flex flex-col justify-center items-center py-5 px-[58px] rounded-[36px] bg-black text-white disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:shadow-none"
                            type="submit"
                            disabled={
                                !(
                                    !loading &&
                                    // checkBox &&
                                    username &&
                                    // firstname &&
                                    // lastname &&
                                    // organisation &&
                                    !emailError
                                )
                            }
                        >
                            {loading ? (
                                <CircularProgress
                                    sx={{
                                        color: "#343434",
                                        fontSize: 14,
                                        width: "20px !important",
                                        height: "20px !important",
                                    }}
                                />
                            ) : (
                                <p className={"text-sm font-bold"}>Register interest</p>
                            )}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default RequestConference;
