import s from "../core/styles/general.styles";

import { useEffect } from "react";

const CareersPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <section
                className={`pt-6 sm:pt-6 md:pt-10 lg:pt-10 xl:pt-10 2xl:pt-10 pb-10 ${s.sectionPaddings}`}
            >
                <div id="ashby_embed" />
            </section>
        </div>
    );
};

export default CareersPage;
