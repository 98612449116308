import axios from "axios";

export const s = {
  //   heroPaddings: `
  //   px-[25px] sm:px-[25px] md:px-[25px] lg:px-[120px] xl:px-[120px] 2xl:px-[120px]
  //   py-[25px] sm:py-[30px] md:py-[30px] lg:pt-[30px] xl:pt-[30px] 2xl:pt-[30px]
  // `,
  heroPaddings: `
px-[25px] sm:px-[25px] md:px-[25px] lg:px-[120px] xl:px-[120px] 2xl:px-[120px]
py-[25px] sm:py-[30px] md:py-[30px] lg:pt-[30px] xl:pt-[30px] 2xl:pt-[30px]
`,
  heroPaddingsEvCharging: `
  px-[25px] sm:px-[25px] md:px-[25px] lg:px-[120px] xl:px-[120px] 2xl:px-[120px]
  py-[25px] sm:py-[30px] md:py-[80px] lg:pt-[60px] xl:pt-[70px] 2xl:pt-[30px]
  `,
  heroInner: "lg:w-[1000px] xl:w-[1149px] 2xl:w-[1149px] lg:mx-auto",
  sectionPaddings:
    "px-[25px] sm:px-[25px] lg:px-[120px] 2xl:px-[120px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1200px] lg:mx-auto xl:mx-auto 2xl:mx-auto",
  container: {
    cols2:
      "flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row",
    cols2Inverted:
      "flex flex-col-reverse sm:flex-col-reverse md:flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row",
    col: "basis-full sm:basis-full md:basis-full lg:basis-1/2 xl:basis-1/2 2xl:basis-1/2",
  },
};

export const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 2000 : -2000,
      opacity: 0,
      display: "none",
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    display: "block",
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 2000 : -2000,
      opacity: 0,
      display: "none",
    };
  },
};

export const swipeConfidenceThreshold = 10000;

export const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const featureSections = [
  {
    id: 1,
    name: "Company diligence",
    mobileName: "Company<br />diligence",
    title: "Automated due diligence workflows",
    tags: [
      "Company strategy",
      "Competitive analysis",
      "Go-to-market strategy",
      "Data room synthesis",
      "Financial analysis",
      "Company filings",
    ],
    img: "/company.png",
    quotes: [
      {
        id: 1,
        quote:
          "Gather intelligence on any private or public company (seed to IPO)",
      },
      {
        id: 2,
        quote:
          "Extract deep insights across product, financials, competition, and more",
      },
      {
        id: 3,
        quote:
          "Create company profiles, diligence reports, and investment memos instantly",
      },
    ],
  },
  {
    id: 2,
    name: "Sector research",
    mobileName: "Sector<br />research",
    title: "Comprehensive data-driven research",
    tags: [
      "Market sizing",
      "Signals and trends",
      "Expert insights",
      "Value chain analysis",
      "Competitive landscape",
      "Market dynamics",
    ],
    img: "/sector.png",
    quotes: [
      {
        id: 1,
        quote:
          "Analyze market dynamics, value chain, and perform market sizing",
      },
      {
        id: 2,
        quote:
          "Decipher signals from sector reports, transcripts, and analyst views",
      },
      {
        id: 3,
        quote:
          "Generate industry overviews, market maps and sector insights in minutes",
      },
    ],
  },
  {
    id: 3,
    name: "Open ended research",
    mobileName: "Open ended<br />research",
    title: "Fact-based analysis with precise citations",
    tags: [
      "Q&A",
      "Reading list",
      "Brainstorming",
      "Quantitative insights",
      "Custom templates",
      "Strategy frameworks",
    ],
    img: "/research.png",
    quotes: [
      {
        id: 1,
        quote:
          "Natural language Q&A for broad based or hyper-specific questions",
      },
      {
        id: 2,
        quote:
          "Upload and analyze multiple documents for building investment thesis",
      },
      {
        id: 3,
        quote: "Customize templates to automate your unique research workflows",
      },
    ],
  },
];

export const customers = [
  {
    id: 1,
    name: "Steven Imes IV",
    role: "VC Analyst, Sage Collective",
    description: `When it comes to investment due diligence, Wokelo has been a game-changer for me. I've used many generative AI tools over the past year, but Wokelo's industry & company research tools have provided the most accurate and comprehensive reports. As an analyst combing through dozens of documents daily, the Data Room Synthesis helps me extract insights from huge data rooms and draft investment memos. Wokelo helps me get smart fast, so I can focus my research in the right places.`,
    img: "/client/logos/sage.png",
    profileImg: "/client/profile/steven.png",
    pad: 5,
  },
  {
    id: 2,
    name: "Jackie Churchwell",
    role: "Founder, Gratia Consulting",
    description: `I recently had a consulting project related to climate tech, which required an in-depth analysis of market size, market economics, competitor offerings, and trends. There was minimal data available in this private sector, and Wokelo.AI was able to provide insights in minutes that would have normally taken weeks to manually compile in an executive-ready presentation. AI tools like Wokelo can significantly improve productivity to support human consultants in an impactful way.`,
    img: "/client/logos/gratia.png",
    profileImg: "/client/profile/jackie.png",
    pad: 5,
  },
  {
    id: 3,
    name: "Jonathan Azoff",
    role: "General Partner, SNØCAP",
    description: `At SNØCAP, we are always looking to streamline our research workflows. As a fund, we are regularly conducting countless hours of research to supplement our understanding of emerging technologies.
    Fortunately, we came across Wokelo.ai. One trial of this platform and I was hooked. It enables me to produce market overviews, generate detailed insights on specialized subject matter, and conduct diligence in a fraction of the previously required time. I couldn't be more excited to get my time back`,
    img: "/client/logos/snocap.svg",
    profileImg: "/client/profile/jonathan.png",
    pad: 5,
  },
  {
    id: 4,
    name: "Yohei Nakajima",
    role: "General Partner, Untapped Capital",
    description: `Over the past 2 years we have built several in-house Gen-AI solutions for automating our investment process. As a builder, I also devoted considerable effort to building an AI-powered diligence tool. We recently came across Wokelo.ai, the ability to instantly synthesize insights from diverse sources, helps us make faster and smarter investment decisions. Excited to see what we can do when we incorporate this into our BabyAGI agent. For a streamlined VC firm like ours, Wokelo has enhanced our due diligence process tenfold`,
    img: "/client/logos/untapped.svg",
    profileImg: "/client/profile/Yohei.jpg",
    imageSize: "smaller",
    pad: 0,
  },
  {
    id: 5,
    name: "Tyler Dean",
    role: "Co-founder, Confluence.VC",
    description: `Partnering with Wokelo has been nothing short of transformative for us at Confluence.VC. We've been able to provide the VC community with unparalleled insights across diverse topics, fundamentally elevating their productivity. It's not just about working smarter, it's about reshaping the future of venture capital.`,
    img: "/client/logos/confluence.png",
    profileImg: "/client/profile/tyler.png",
    imageSize: "smaller",
    pad: 5,
  },
];

type faqProps = {
  id: number;
  title: string;
  description: string;
  open: boolean;
};

export const faqs: faqProps[] = [
  {
    id: 1,
    title:
      "How accurate are the results, how does Wokelo eliminate hallucinations?",
    description:
      "Wokelo is a privacy centered secure platform. Some of the most highly regulated financial services players and banks trust Wokelo with their confidential and proprietary data. We are SOC 2 compliant, which ensures your data is secure and encrypted at all times. Internal company data is not used for model training. Any uploaded internal data on Wokelo is automatically deleted after 30-days. Data deletion can also be initiated on-demand by customer. We support both cloud hosted and on-premise deployments to support varying needs of organizations.",
    open: false,
  },
  {
    id: 2,
    title: "How will Wokelo keep my internal company data private and secure?",
    description:
      "Wokelo is a privacy centered secure platform. Some of the most highly regulated financial services players and banks trust Wokelo with their confidential and proprietary data. We are SOC 2 compliant, which ensures your data is secure and encrypted at all times. Internal company data is not used for model training. Any uploaded internal data on Wokelo is automatically deleted after 30-days. Data deletion can also be initiated on-demand by customer. We support both cloud hosted and on-premise deployments to support varying needs of organizations.",
    open: false,
  },
  {
    id: 3,
    title:
      "Which data sources come pre-integrated with Wokelo, do I have to pay more?",
    description:
      "Wokelo is a privacy centered secure platform. Some of the most highly regulated financial services players and banks trust Wokelo with their confidential and proprietary data. We are SOC 2 compliant, which ensures your data is secure and encrypted at all times. Internal company data is not used for model training. Any uploaded internal data on Wokelo is automatically deleted after 30-days. Data deletion can also be initiated on-demand by customer. We support both cloud hosted and on-premise deployments to support varying needs of organizations.",
    open: false,
  },
  {
    id: 4,
    title: "How does Wokelo save me time and money?",
    description:
      "Wokelo is a privacy centered secure platform. Some of the most highly regulated financial services players and banks trust Wokelo with their confidential and proprietary data. We are SOC 2 compliant, which ensures your data is secure and encrypted at all times. Internal company data is not used for model training. Any uploaded internal data on Wokelo is automatically deleted after 30-days. Data deletion can also be initiated on-demand by customer. We support both cloud hosted and on-premise deployments to support varying needs of organizations.",
    open: false,
  },
  {
    id: 5,
    title: "What type of customizations are supported by Wokelo?",
    description:
      "Wokelo is a privacy centered secure platform. Some of the most highly regulated financial services players and banks trust Wokelo with their confidential and proprietary data. We are SOC 2 compliant, which ensures your data is secure and encrypted at all times. Internal company data is not used for model training. Any uploaded internal data on Wokelo is automatically deleted after 30-days. Data deletion can also be initiated on-demand by customer. We support both cloud hosted and on-premise deployments to support varying needs of organizations.",
    open: false,
  },
];

export const downloadReport = async () => {
  try {
    // const response = await axios.get("https://aqua-oralia-69.tiiny.site/Industry%20QA%20-%20AI%20Development-2024-04-04T06-31-00.281Z.pdf", {
    //   responseType: "blob", // Important
    // });
    // const blob = new Blob([response.data], { type: "application/pdf" });
    // const url = window.URL.createObjectURL(blob);
    let url = "/reportFiles/EVCharging.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "EV Charging Stations in the US.pdf");
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};
