import React, { useEffect } from "react";
import Soc2 from "../Home/WhyCustomerLoveWokelo";
import TrustedBy from "../Home/TrustedBy";
import Form from "./Form";
import Footer from "../Footer";
import WhyCustomerLovesWokeloCarouselEvChargingUs from "../Home/WhyCustomerLoveWokeloCarouselEvCharging";
import axios from "axios";
import WokeloLoaderAnimation from "../WokeloAnimation";
import { Api } from "../../hooks/Api";

type Props = {};

const CampaignRequestAccess = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const source = location?.href?.split('/').pop()
  const [data,setData] = React.useState({})
  const [isLoading,setIsLoading] = React.useState(false);
  useEffect(()=>{
    setIsLoading(true)
    Api.getCampaignData(source).then(res=>{
      setData(res)
      setIsLoading(false)
    })
  },[])
  return (
    <>
    {isLoading? <div className="flex flex-col justify-center items-center h-screen w-full">
    <WokeloLoaderAnimation/>
    </div>:
    <div className="flex flex-col relative">
      <Form source={String(source)} title={data['data']?.label} encodedUrl={String(source)}/>
      <TrustedBy leftAligned={true} />
      <WhyCustomerLovesWokeloCarouselEvChargingUs
        title="Learn how we can help you accelerate investment research"
        titleClassName="text-[24px] leading-[28px] md:text-[36px] md:leading-[43px]"
        subTitleClassName="md:w-full"
        subtitle="Leading professionals are leveraging Wokelo to get ahead of the curve"
      />
      <Footer></Footer>
    </div>
  }
  </>
  );
};

export default CampaignRequestAccess;
