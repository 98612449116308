import * as React from "react";

function Star(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      {...props}
    >
      <path
        d="M13.9737 9.24911L22.4211 5.97509L24 9.90391L15.3947 13.0142L20.6053 20.4626L17.3684 23L11.9211 15.1423L6.78947 23L3.23684 20.4626L8.36842 13.0142L0 9.90391L1.42105 5.97509L9.78947 9.24911V0H13.9737V9.24911Z"
        fill="#E4DCD2"
      />
    </svg>
  );
}

const StarIcon = React.memo(Star);
export default StarIcon;
