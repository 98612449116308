import { Player, Controls } from '@lottiefiles/react-lottie-player';
import loaderAnimationJson from '../utils/LoaderAnimation.json'

function WokeloLoaderAnimation() {
    return (
        <Player
            autoplay
            speed={1}
            loop
            className='w-60 h-32'
            src={loaderAnimationJson}
            background="tranparent"
        >
            <Controls
                visible={false}
                buttons={[
                    "play",
                    "repeat",
                    "frame",
                    "debug",
                    "snapshot",
                    "background",
                ]}
            />
        </Player>

    )
}

export default WokeloLoaderAnimation