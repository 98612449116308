import React from 'react';
import { ComponentProps } from '../types/component-props';

export type TagProps = {
	icon?: React.ReactNode;
} & ComponentProps;

const Tag = ({ children, icon, className }: TagProps) => {
	return (
		<span
			className={`bg-[#B6BEB536] border border-w-medium-gray py-2 px-[10px] gap-[10px] flex flex-row items-center rounded-[40px] text-[12px] leading-[14px] ${className}`}
		>
			{icon ? icon : null}

			{children}
		</span>
	);
};

export default Tag;
