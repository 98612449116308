import * as React from "react";

function Lock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M10 13.3333V11.6666C10 6.14378 14.4771 1.66663 20 1.66663C25.5228 1.66663 30 6.14378 30 11.6666V13.3333H33.3333C34.2538 13.3333 35 14.0795 35 15V35C35 35.9205 34.2538 36.6666 33.3333 36.6666H6.66667C5.7462 36.6666 5 35.9205 5 35V15C5 14.0795 5.7462 13.3333 6.66667 13.3333H10ZM31.6667 16.6666H8.33333V33.3333H31.6667V16.6666ZM18.3333 26.2206C17.337 25.6443 16.6667 24.5671 16.6667 23.3333C16.6667 21.4923 18.159 20 20 20C21.841 20 23.3333 21.4923 23.3333 23.3333C23.3333 24.5671 22.663 25.6443 21.6667 26.2206V30H18.3333V26.2206ZM13.3333 13.3333H26.6667V11.6666C26.6667 7.98473 23.6818 4.99996 20 4.99996C16.3181 4.99996 13.3333 7.98473 13.3333 11.6666V13.3333Z"
        fill="#343434"
      />
    </svg>
  );
}

const LockIcon = React.memo(Lock);
export default LockIcon;
