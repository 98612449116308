import React, { useEffect } from "react";
import Soc2 from "../Home/WhyCustomerLoveWokelo";
import TrustedBy from "../Home/TrustedBy";
import Form from "./Form";
import Footer from "../Footer";
import WhyCustomerLovesWokeloCarousel from "../Home/WhyCustomerLoveWokeloCarousel";
import TrustedByCopy from "../Home/TrustedbyCopy";

type Props = {};

const ConfluenceAccess = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col relative">
      <Form />
      <TrustedByCopy leftAligned={true} />
      <WhyCustomerLovesWokeloCarousel
        title="Learn how we can help you accelerate investment research"
        titleClassName="text-[24px] leading-[28px] md:text-[36px] md:leading-[43px]"
        subTitleClassName="md:w-full"
        subtitle="Leading professionals are leveraging Wokelo to get ahead of the curve"
      />
      <Footer></Footer>
    </div>
  );
};

export default ConfluenceAccess;
