import * as React from "react";

function Corporatestrategy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        d="M25.3125 6.5625H20.625V5.625C20.625 4.87908 20.3287 4.16371 19.8012 3.63626C19.2738 3.10882 18.5584 2.8125 17.8125 2.8125H12.1875C11.4416 2.8125 10.7262 3.10882 10.1988 3.63626C9.67132 4.16371 9.375 4.87908 9.375 5.625V6.5625H4.6875C4.19022 6.5625 3.71331 6.76004 3.36167 7.11167C3.01004 7.46331 2.8125 7.94022 2.8125 8.4375V23.4375C2.8125 23.9348 3.01004 24.4117 3.36167 24.7633C3.71331 25.115 4.19022 25.3125 4.6875 25.3125H25.3125C25.8098 25.3125 26.2867 25.115 26.6383 24.7633C26.99 24.4117 27.1875 23.9348 27.1875 23.4375V8.4375C27.1875 7.94022 26.99 7.46331 26.6383 7.11167C26.2867 6.76004 25.8098 6.5625 25.3125 6.5625ZM11.25 5.625C11.25 5.37636 11.3488 5.1379 11.5246 4.96209C11.7004 4.78627 11.9389 4.6875 12.1875 4.6875H17.8125C18.0611 4.6875 18.2996 4.78627 18.4754 4.96209C18.6512 5.1379 18.75 5.37636 18.75 5.625V6.5625H11.25V5.625ZM25.3125 8.4375V13.3137C22.1482 15.0361 18.6027 15.9381 15 15.9375C11.3974 15.9382 7.85206 15.0365 4.6875 13.3148V8.4375H25.3125ZM25.3125 23.4375H4.6875V15.4266C7.89835 16.9971 11.4256 17.8132 15 17.8125C18.5744 17.8126 22.1017 16.9961 25.3125 15.4254V23.4375ZM12.1875 13.125C12.1875 12.8764 12.2863 12.6379 12.4621 12.4621C12.6379 12.2863 12.8764 12.1875 13.125 12.1875H16.875C17.1236 12.1875 17.3621 12.2863 17.5379 12.4621C17.7137 12.6379 17.8125 12.8764 17.8125 13.125C17.8125 13.3736 17.7137 13.6121 17.5379 13.7879C17.3621 13.9637 17.1236 14.0625 16.875 14.0625H13.125C12.8764 14.0625 12.6379 13.9637 12.4621 13.7879C12.2863 13.6121 12.1875 13.3736 12.1875 13.125Z"
        fill="black"
      />
    </svg>
  );
}

const CorporatestrategyIcon = React.memo(Corporatestrategy);
export default CorporatestrategyIcon;
