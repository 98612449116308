import React from "react";
import { ComponentProps } from "../types/component-props";

const Heading2 = ({ className, children }: ComponentProps) => {
  return (
    <h2
      className={`lg:text-[45px] lg:leading-[54px] text-[28px] leading-[34px] md:text-[32px] md:leading-[38px] ${className}`}
    >
      {children}
    </h2>
  );
};

export default Heading2;
