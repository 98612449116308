import React, { useEffect, useState } from "react";
import { featureSections, s } from "./utils";
import Heading2 from "../Heading2";
import Heading3 from "../Heading3";
import Tag from "../Tag";
import { LinearProgress } from "@mui/material";
import "./featureSection/featureSection.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

type Props = {};

const TabContent = ({ title, name, quotes, tags, image }) => {
  return (
    <div className={`my-0 md:my-10`}>
      <div className="w-full">
        <Heading2 className="lg:flex xl:flex 2xl:flex">{title}</Heading2>
      </div>
      <div className={`w-full flex flex-col md:flex-row items-start gap-2`}>
        <div className={`w-full md:w-[60%] flex mt-3 md:mt-8 h-auto`}>
          <div className="flex flex-col items-start justify-center">
            <Heading3 className="font-[550] mb-[10px] md:mb-[20px] pt-4">
              {name}
            </Heading3>
            <div className="ml-4 md:ml-5 flex-col hidden md:flex">
              <ul className="list-disc list-outside text-[14px] leading-[15px] md:text-[16px] md:leading-[17px]">
                {quotes?.map((q) => (
                  <li key={q.id} className="my-5">
                    {q.quote}
                  </li>
                ))}
              </ul>
            </div>
            <div className="sm:mt-3 md:mt-10 flex flex-row w-[80%] flex-wrap gap-2">
              {tags.map((t) => (
                <Tag key={t}>{t}</Tag>
              ))}
            </div>
          </div>
        </div>
        {/* Images container */}
        <div
          className={`relative w-full mt-3 md:mt-8 md:w-[40%] h-auto flex items-center justify-center`}
        >
          {/* Image */}
          <picture>
            <source srcSet={image} type="image/webp" />
            <img
              src={image}
              alt="Hero"
              className="z-30 w-[480px] object-scale-down"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

const FeatureSection = React.forwardRef((props: Props, ref: any) => {
  const [activeTab, setActiveTab] = useState(1);
  const [scrollY, setScrollY] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  function logit() {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    if (scrollY < 2000) {
      if (activeTab != 1) {
        setActiveTab(1);
      }
    } else if (scrollY > 2000 && scrollY < 2700) {
      if (activeTab != 2) {
        setActiveTab(2);
      }
    } else {
      if (activeTab != 3) {
        setActiveTab(3);
      }
    }
    console.log(scrollY);
  }, [scrollY]);

  const changeActiveTab = (activeTab) => {
    if (activeTab == 1) {
      const element = document.getElementById("product");
      const el = document.getElementById("product-tab");
      console.log(el?.offsetTop);

      const offsetTop = element?.offsetTop;
      setScrollY(offsetTop ? offsetTop + 24 : 1999);
      window.scrollTo(0, offsetTop ? offsetTop + 24 : 1999);
    } else if (activeTab == 2) {
      setScrollY(2699);
      window.scrollTo(0, 2699);
    } else if (activeTab == 3) {
      setScrollY(2701);
      window.scrollTo(0, 2701);
    }
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  return (
    <section className={`flex flex-col ${s.heroPaddings}`} id="product">
      <div className="scroll-tabs relative flex flex-col">
        <div className="tab pt-0 md:pt-[24px] z-20 bgGrd" id="product-tab">
          <div className="tab grid grid-cols-3">
            {featureSections?.map((item, index) => (
              <div
                key={index}
                className={`text-left w-full text-dark-black cursor-pointer h-[120px] flex pb-1 flex-end px-2 ${
                  activeTab === item?.id ? "" : "opacity-50"
                } ${item?.id !== 1 ? "pl-3 md:pl-10" : ""}`}
                onClick={() => changeActiveTab(item?.id)}
                dangerouslySetInnerHTML={{
                  __html: isMobile ? item?.mobileName : item?.name,
                }}
              ></div>
            ))}
          </div>

          <div className="w-full mt-4">
            <LinearProgress
              variant="determinate"
              value={activeTab === 1 ? 33 : activeTab === 2 ? 66 : 100}
              sx={{
                backgroundColor: "#D9D9D9",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#000",
                },
              }}
            />
          </div>
        </div>
        <div className="et_pb_row z-10 mt-3">
          <div ref={ref}>
            <TabContent
              title={featureSections[activeTab - 1]?.title}
              name={featureSections[activeTab - 1]?.name}
              quotes={featureSections[activeTab - 1]?.quotes}
              tags={featureSections[activeTab - 1]?.tags}
              image={featureSections[activeTab - 1]?.img}
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default FeatureSection;
