import * as React from "react";

function Line(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="860"
      height="2"
      viewBox="0 0 860 2"
      fill="none"
      {...props}
    >
      <path
        d="M1 1H859"
        stroke="url(#paint0_radial_989_2279)"
        stroke-linecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_989_2279"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(430 1.5) rotate(90) scale(0.5 429)"
        >
          <stop stop-color="#FFF0E5" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

const LineIcon = React.memo(Line);
export default LineIcon;
