import * as React from "react";

function Arrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      {...props}
    >
      <path
        d="M1.5 6.5C0.947715 6.5 0.5 6.94772 0.5 7.5C0.5 8.05228 0.947715 8.5 1.5 8.5V6.5ZM20.2071 8.20711C20.5976 7.81658 20.5976 7.18342 20.2071 6.79289L13.8431 0.428932C13.4526 0.0384078 12.8195 0.0384078 12.4289 0.428932C12.0384 0.819457 12.0384 1.45262 12.4289 1.84315L18.0858 7.5L12.4289 13.1569C12.0384 13.5474 12.0384 14.1805 12.4289 14.5711C12.8195 14.9616 13.4526 14.9616 13.8431 14.5711L20.2071 8.20711ZM1.5 8.5H19.5V6.5H1.5V8.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

const ArrowIcon = React.memo(Arrow);
export default ArrowIcon;
