const s = {
	heroPaddings:
		'px-[20px] sm:px-[20px] md:px-[20px] lg:px-[76px] xl:px-[76px] 2xl:px-[76px]',
	heroInner: 'lg:w-[1000px] xl:w-[1149px] 2xl:w-[1149px] lg:mx-auto',
	sectionPaddings:
		'px-[20px] sm:px-[20px] md:px-[20px] lg:px-[52px] xl:px-[134px] 2xl:px-[134px] lg:w-[1000px] xl:w-[1149px] 2xl:w-[1149px] lg:mx-auto xl:mx-auto 2xl:mx-auto',
	container: {
		cols2: 'flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row',
		cols2Inverted:
			'flex flex-col-reverse sm:flex-col-reverse md:flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row',
		col: 'basis-full sm:basis-full md:basis-full lg:basis-1/2 xl:basis-1/2 2xl:basis-1/2',
	},
};

export default s;
