import axios from "axios";

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetcher = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
});

export const Api = {
  async requestAccess(payload: any) {
    try {
      const response = await fetcher(`/create-or-update-deal/ `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      }
    }
  },

  async getCampaignData(source) {
    try {
      const response = await fetcher(`/get-campgain `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: 
          {'source':source}
        ,
      });
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      }
    }
  },

  async slotBookedNotification(payload: any) {
    try {
      const response = await fetcher(`/slot-booked/ `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      }
    }
  },
};
