import React from "react";
import { ButtonHTMLAttributes } from "react";

export type ButtonVariant =
  | "primary"
  | "transparent"
  | "outlined"
  | "secondary"
  | "cream";

export const buttonVariants: { [key in ButtonVariant]: string } = {
  primary: "bg-w-black text-w-light-white shadow-sm shadow-gray-300/30",
  secondary: "bg-w-light-white text-w-black shadow-gray-300/30",
  cream: "bg-w-cream text-w-black shadow-gray-300/30",
  transparent: "text-w-purple hover:bg-gray-200",
  outlined:
    "bg-[#202020] border border-w-light-white text-white hover:shadow-md hover:shadow-gray-300/30",
};

export type ButtonProps = {
  variant?: ButtonVariant;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ variant, className, children, ...props }: ButtonProps) => {
  return (
    <button
      className={`font-[500] transition-all truncate flex flex-row items-center justify-center font-normal text-[12px] md:text-[16px] leading-[16px] px-5 md:px-6 gap-3 rounded-[40px] ${
        buttonVariants[variant ?? "primary"]
      } ${props.disabled ? "cursor-not-allowed" : ""} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
