import * as React from "react";

function ShieldCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="44"
      viewBox="0 0 43 44"
      fill="none"
      {...props}
    >
      <path
        d="M38.6875 0.59375H4.3125C3.40082 0.59375 2.52648 0.955914 1.88182 1.60057C1.23716 2.24523 0.875 3.11957 0.875 4.03125V16.6598C0.875 35.9119 17.1645 42.2992 20.4258 43.3842C21.1223 43.6211 21.8777 43.6211 22.5742 43.3842C25.8398 42.2992 42.125 35.9119 42.125 16.6598V4.03125C42.125 3.11957 41.7628 2.24523 41.1182 1.60057C40.4735 0.955914 39.5992 0.59375 38.6875 0.59375ZM38.6875 16.6619C38.6875 33.51 24.4326 39.1389 21.5 40.1186C18.5932 39.1496 4.3125 33.525 4.3125 16.6619V4.03125H38.6875V16.6619ZM11.6902 22.4348C11.3677 22.1123 11.1865 21.6748 11.1865 21.2187C11.1865 20.7627 11.3677 20.3252 11.6902 20.0027C12.0127 19.6802 12.4502 19.499 12.9062 19.499C13.3623 19.499 13.7998 19.6802 14.1223 20.0027L18.0625 23.943L28.8777 13.1277C29.0374 12.968 29.227 12.8414 29.4356 12.7549C29.6443 12.6685 29.8679 12.624 30.0938 12.624C30.3196 12.624 30.5432 12.6685 30.7519 12.7549C30.9605 12.8414 31.1501 12.968 31.3098 13.1277C31.4695 13.2874 31.5961 13.477 31.6826 13.6856C31.769 13.8943 31.8135 14.1179 31.8135 14.3437C31.8135 14.5696 31.769 14.7932 31.6826 15.0019C31.5961 15.2105 31.4695 15.4001 31.3098 15.5598L19.2785 27.591C19.1189 27.7508 18.9293 27.8776 18.7207 27.9641C18.512 28.0506 18.2884 28.0951 18.0625 28.0951C17.8366 28.0951 17.613 28.0506 17.4043 27.9641C17.1957 27.8776 17.0061 27.7508 16.8465 27.591L11.6902 22.4348Z"
        fill="white"
      />
    </svg>
  );
}

const ShieldCheckIcon = React.memo(ShieldCheck);
export default ShieldCheckIcon;
