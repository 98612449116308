import * as React from "react";

function Quote(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119"
      height="78"
      viewBox="0 0 119 78"
      fill="none"
      {...props}
    >
      <path
        d="M0 48.0951C0 23.5303 19.8925 -1.03441 52.2179 0.0336106V14.6301C33.391 14.6301 20.2478 26.0224 18.4716 41.3309C21.3134 39.9068 24.1552 39.5508 26.6418 39.5508C38.009 39.5508 46.1791 47.7391 46.1791 58.4194C46.1791 70.1678 36.9433 78 24.8657 78C8.52537 78 0 65.1836 0 48.0951ZM66.7821 48.0951C66.7821 23.5303 86.6746 -1.03441 119 0.0336106V14.6301C100.173 14.6301 87.0298 26.0224 85.2537 41.3309C88.0955 39.9068 90.9373 39.5508 93.7791 39.5508C104.791 39.5508 112.961 47.7391 112.961 58.4194C112.961 70.1678 104.081 78 91.6478 78C75.3074 78 66.7821 65.1836 66.7821 48.0951Z"
        fill="white"
        fill-opacity="0.05"
      />
    </svg>
  );
}

const QuoteIcon = React.memo(Quote);
export default QuoteIcon;
